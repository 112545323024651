/*
 *
 * ONI constants
 *
 */

export const DESELECT_BRANDS = 'app/Oni/DESELECT_BRANDS';

export const LOAD_ONI_BRAND = 'app/Oni/LOAD_ONI_BRAND';
export const LOAD_ONI_BRAND_SUCCESS = 'app/Oni/LOAD_ONI_BRAND_SUCCESS';
export const LOAD_ONI_BRAND_FAIL = 'app/Oni/LOAD_ONI_BRAND_FAIL';
export const LOAD_ONI_BRANCH = 'app/Oni/LOAD_ONI_BRANCH';
export const LOAD_ONI_BRANCH_SUCCESS = 'app/Oni/LOAD_ONI_BRANCH_SUCCESS';
export const LOAD_ONI_BRANCH_FAIL = 'app/Oni/LOAD_ONI_BRANCH_FAIL';

export const CHANGE_BRAND = 'app/Oni/CHANGE_BRAND';
export const CHANGE_BRANCH = 'app/Oni/CHANGE_BRANCH';

export const LOAD_PROVIDERS = 'app/Oni/LOAD_PROVIDERS';
export const LOAD_PROVIDERS_SUCCESS = 'app/Oni/LOAD_PROVIDERS_SUCCESS';
export const LOAD_PROVIDERS_FAILED = 'app/Oni/LOAD_PROVIDERS_FAILED';

export const LOAD_STATUS = 'app/Oni/LOAD_STATUS';
export const LOAD_STATUS_SUCCESS = 'app/Oni/LOAD_STATUS_SUCCESS';
export const LOAD_STATUS_FAILED = 'app/Oni/LOAD_STATUS_FAILED';

export const LOAD_LIVE_ORDERS = 'app/Oni/LOAD_LIVE_ORDERS';
export const LOAD_LIVE_ORDERS_SUCCESS = 'app/Oni/LOAD_LIVE_ORDERS_SUCCESS';
export const LOAD_LIVE_ORDERS_FAILED = 'app/Oni/LOAD_LIVE_ORDERS_FAILED';

export const LOAD_LIVE_NEW_ORDERS = 'app/Oni/LOAD_LIVE_NEW_ORDERS';
export const LOAD_LIVE_NEW_ORDERS_SUCCESS =
  'app/Oni/LOAD_LIVE_NEW_ORDERS_SUCCESS';
export const LOAD_LIVE_NEW_ORDERS_FAILED =
  'app/Oni/LOAD_LIVE_NEW_ORDERS_FAILED';

export const LOAD_LIVE_ORDER = 'app/Oni/LOAD_LIVE_ORDER';
export const LOAD_LIVE_ORDER_SUCCESS = 'app/Oni/LOAD_LIVE_ORDER_SUCCESS';
export const LOAD_LIVE_ORDER_FAILED = 'app/Oni/LOAD_LIVE_ORDER_FAILED';

export const START_ORDER_STATUS = 'app/Oni/START_ORDER_STATUS';
export const START_ORDER_STATUS_SUCCESS = 'app/Oni/START_ORDER_STATUS_SUCCESS';
export const START_ORDER_STATUS_FAILED = 'app/Oni/START_ORDER_STATUS_FAILED';

export const LOAD_BIZ_ONI = 'app/Oni/LOAD_BIZ_ONI';
export const LOAD_BIZ_ONI_SUCCESS = 'app/Oni/LOAD_BIZ_ONI_SUCCESS';
export const LOAD_BIZ_ONI_FAILED = 'app/Oni/LOAD_BIZ_ONI_FAILED';

export const CHANGE_ONI_BIZ = 'app/Oni/CHANGE_ONI_BIZ';

export const CHANGE_SELECTED_ORDER_HISTORY_MODE =
  'app/Oni/CHANGE_SELECTED_ORDER_HISTORY_MODE';

export const LOAD_ORDER_SOURCE = 'app/Oni/LOAD_ORDER_SOURCE';
export const LOAD_ORDER_SOURCE_SUCCESS = 'app/Oni/LOAD_ORDER_SOURCE_SUCCESS';
export const LOAD_ORDER_SOURCE_FAILED = 'app/Oni/LOAD_ORDER_SOURCE_FAILED';

export const LOAD_LIVE_SCHEDULED_ORDERS_SUCCESS =
  'app/Oni/LOAD_LIVE_SCHEDULED_ORDERS_SUCCESS';
export const UPDATE_PAYMENT_INFO = 'app/Oni/UPDATE_PAYMENT_INFO';
export const UPDATE_PAYMENT_INFO_SUCCESS =
  'app/Oni/UPDATE_PAYMENT_INFO_SUCCESS';

// ONI Integration status constants

export const LOAD_BRANDS_FOR_INTEGRATION_STATUS =
  'app/Oni/LOAD_BRANDS_FOR_INTEGRATION_STATUS';
export const LOAD_BRANDS_FOR_INTEGRATION_STATUS_SUCCESS =
  'app/Oni/LOAD_BRANDS_FOR_INTEGRATION_STATUS_SUCCESS';
export const LOAD_BRANDS_FOR_INTEGRATION_STATUS_FAILED =
  'app/Oni/LOAD_BRANDS_FOR_INTEGRATION_STATUS_FAILED';

export const LOAD_BRANCHES_FOR_INTEGRATION_STATUS =
  'app/Oni/LOAD_BRANCHES_FOR_INTEGRATION_STATUS';
export const LOAD_BRANCHES_FOR_INTEGRATION_STATUS_SUCCESS =
  'app/Oni/LOAD_BRANCHES_FOR_INTEGRATION_STATUS_SUCCESS';
export const LOAD_BRANCHES_FOR_INTEGRATION_STATUS_FAILED =
  'app/Oni/LOAD_BRANCHES_FOR_INTEGRATION_STATUS_FAILED';

export const CHANGE_BRAND_FOR_INTEGRATION_STATUS =
  'app/Oni/CHANGE_BRAND_FOR_INTEGRATION_STATUS';

export const CHANGE_BRANCH_FOR_INTEGRATION_STATUS =
  'app/Oni/CHANGE_BRANCH_FOR_INTEGRATION_STATUS';

export const LOAD_PROVIDERS_FOR_INTEGRATION_STATUS =
  'app/Oni/LOAD_PROVIDERS_FOR_INTEGRATION_STATUS';
export const LOAD_PROVIDERS_FOR_INTEGRATION_STATUS_SUCCESS =
  'app/Oni/LOAD_PROVIDERS_FOR_INTEGRATION_STATUS_SUCCESS';
export const LOAD_PROVIDERS_FOR_INTEGRATION_STATUS_FAILED =
  'app/Oni/LOAD_PROVIDERS_FOR_INTEGRATION_STATUS_FAILED';

export const LOAD_INTEGRATION_STATUS = 'app/Oni/LOAD_INTEGRATION_STATUS';
export const LOAD_INTEGRATION_STATUS_SUCCESS =
  'app/Oni/LOAD_INTEGRATION_STATUS_SUCCESS';
export const LOAD_INTEGRATION_STATUS_FAILED =
  'app/Oni/LOAD_INTEGRATION_STATUS_FAILED';

// ONI tab constant
export const ALL_ORDER_TAB = 'all';
export const NEW_ORDER_TAB = 'new';
export const RUNNING_ORDER_TAB = 'others';
export const READY_ORDER_TAB = 'ready';
export const SCHEDULED_ORDER_TAB = 'scheduled';

// ONI date Range Type
export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const RANGE = 'range';

export const LOAD_PAYMENT_CHANNELS_All =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_All';
export const LOAD_PAYMENT_CHANNELS_All_SUCCESS =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_All_SUCCESS';
export const LOAD_PAYMENT_CHANNELS_All_FAILED =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_All_FAILED';
export const SELECTED_PAYMENT_CHANNEL =
  'app/ManualOrder/SELECTED_PAYMENT_CHANNEL';
export const RESET_SELECTED_PAYMENT_CHANNEL =
  'app/ManualOrder/RESET_SELECTED_PAYMENT_CHANNEL';

export const LOAD_PAYMENT_STATUS_LIST =
  'app/ManualOrder/LOAD_PAYMENT_STATUS_LIST';
export const LOAD_PAYMENT_STATUS_LIST_SUCCESS =
  'app/ManualOrder/LOAD_PAYMENT_STATUS_LIST_SUCCESS';
export const LOAD_PAYMENT_STATUS_LIST_FAILED =
  'app/ManualOrder/LOAD_PAYMENT_STATUS_LIST_FAILED';
export const SELECTED_PAYMENT_STATUS =
  'app/ManualOrder/SELECTED_PAYMENT_STATUS';
export const RESET_SELECTED_PAYMENT_STATUS =
  'app/ManualOrder/RESET_SELECTED_PAYMENT_STATUS';

export const FIND_RIDER = 'app/ManualOrder/FIND_RIDER';
export const FIND_RIDER_SUCCESS = 'app/ManualOrder/FIND_RIDER_SUCCESS';
export const FIND_RIDER_FAILED = 'app/ManualOrder/FIND_RIDER_FAILED';

export const GET_CANCELLATION_REASONS =
  'app/ManualOrder/GET_CANCELLATION_REASONS';
export const GET_CANCELLATION_REASONS_SUCCESS =
  'app/ManualOrder/GET_CANCELLATION_REASONS_SUCCESS';
export const GET_CANCELLATION_REASONS_FAILED =
  'app/ManualOrder/GET_CANCELLATION_REASONS_FAILED';

export const EDIT_CANCELLATION_REASON_DETAILS =
  'app/ManualOrder/EDIT_CANCELLATION_REASON_DETAILS';

export const LOAD_PAYMENT_CHANNELS_BY_COUNTRY =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_BY_COUNTRY';
export const LOAD_PAYMENT_CHANNELS_BY_COUNTRY_SUCCESS =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_BY_COUNTRY_SUCCESS';
export const LOAD_PAYMENT_CHANNELS_BY_COUNTRY_FAILED =
  'app/ManualOrder/LOAD_PAYMENT_CHANNELS_BY_COUNTRY_FAILED';

export const CANCEL_RIDER_ALLOCATION = 'app/Oni/CANCEL_RIDER_ALLOCATION';
export const CANCEL_RIDER_ALLOCATION_SUCCESS =
  'app/Oni/CANCEL_RIDER_ALLOCATION_SUCCESS';
export const CANCEL_RIDER_ALLOCATION_FAILED =
  'app/Oni/CANCEL_RIDER_ALLOCATION_FAILED';

export const GET_ORDER_ATTACHMENTS = 'app/Oni/GET_ORDER_ATTACHMENTS';
export const GET_ORDER_ATTACHMENTS_SUCCESS =
  'app/Oni/GET_ORDER_ATTACHMENTS_SUCCESS';
export const GET_ORDER_ATTACHMENTS_FAILED =
  'app/Oni/GET_ORDER_ATTACHMENTS_FAILED';
export const DELETE_ORDER = 'app/Oni/DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'app/Oni/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILED = 'app/Oni/DELETE_ORDER_FAILED';

export const UPDATE_PREP_TIME = 'app/Oni/UPDATE_PREP_TIME';
export const UPDATE_PREP_TIME_SUCCESS = 'app/Oni/UPDATE_PREP_TIME_SUCCESS';
export const UPDATE_PREP_TIME_FAILED = 'app/Oni/UPDATE_PREP_TIME_FAILED';

export const GET_LIST = 'app/Oni/GET_LIST';
export const GET_LIST_SUCCESS = 'app/Oni/GET_LIST_SUCCESS';
export const GET_LIST_FAILED = 'app/Oni/GET_LIST_FAILED';

export const LOAD_DETAILS = 'app/Oni/LOAD_DETAILS';
export const LOAD_DETAILS_SUCCESS = 'app/Oni/LOAD_DETAILS_SUCCESS';
export const LOAD_DETAILS_FAILED = 'app/Oni/LOAD_DETAILS_FAILED';

export const EDIT_FIELD = 'app/Oni/EDIT_FIELD';

export const SAVE_ENTITY = 'app/Oni/SAVE_ENTITY';
export const SAVE_ENTITY_SUCCESS = 'app/Oni/SAVE_ENTITY_SUCCESS';
export const SAVE_ENTITY_FAILED = 'app/Oni/SAVE_ENTITY_FAILED';
