import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from '../../../../utils/injectReducer';
import reducer from '../../reducer';
import { useInjectSaga } from '../../../../utils/injectSaga';
import saga from '../../saga';
import { isEmpty } from '../../../../utils';
import { getSettings } from '../../actions';
import { AGGREGATORS } from '../../sagaMap';
import { PROVIDER_ROUTE } from '../../../../config/constants/routes';
import localStorage from '../../../../utils/localStorage';

function TiktokRedirect({ getAuthCallback }) {
  useInjectReducer({ key: 'integration', reducer });
  useInjectSaga({ key: 'integration', saga });

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location?.search);
  const code = searchParams.get('code');
  const ttsInfo = localStorage.getItem('tts_info');
  const {
    business_id: businessId = null,
    branch_id: branchId = null,
    brand_id: brandId = null,
    login_url: loginUrl = null,
  } = ttsInfo || {};

  const state = !isEmpty(loginUrl)
    ? new URLSearchParams(new URL(loginUrl).search).get('state')
    : null;

  useEffect(() => {
    if (!isEmpty(state) && !isEmpty(code)) {
      const { url } = AGGREGATORS.TIKTOK.AUTH_CALLBACK;

      const backToProviders = () => {
        navigate(
          `${PROVIDER_ROUTE}?business_id=${businessId}&brand_id=${brandId}&branch_id=${branchId}&open_tts=true`,
        );
      };

      const payload = {
        params: {
          state,
          code,
        },
        url,
        callbackFn: () => {
          backToProviders();
        },
        failCallbackFn: () => {
          backToProviders();
        },
      };
      getAuthCallback(payload);
    }
  }, [code, state]);

  return <h1>Redirecting...</h1>;
}

TiktokRedirect.propTypes = {
  getAuthCallback: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    getAuthCallback: params => dispatch(getSettings(params)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(TiktokRedirect);
