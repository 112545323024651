import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SCButton, SCPagination } from 'rollup-allspark';
import { format } from 'date-fns';
import HelpPopover from '../../../components/HelpPopover';
import { sortAggregatedDataRow } from '../../../utils/nativeAnalyticsUtils';
import { readablePrice } from '../../../utils/getCurrency';
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
} from '../../../config';
import { downloadCsvFromJSON } from '../../../utils/sheetUtils';

const ItemBreakDownTable = ({
  tableData,
  currentTab,
  itemBreakDownDataLoading,
}) => {
  // HOOKS
  const [isNameColumnHeaderIsOpen, setIsNameColumnHeaderIsOpen] =
    useState(false);
  const [isQuantityColumnHeaderIsOpen, setIsQuantityColumnHeaderIsOpen] =
    useState(false);
  const [isRevenueColumnHeaderIsOpen, setIsRevenueColumnHeaderIsOpen] =
    useState(false);
  const [tableFlattenedData, setTableFlattenedData] = useState([]);
  const [tableFlattenedDataCSV, setTableFlattenedDataCSV] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NO);
  const [currentSize, setCurrentSize] = useState(DEFAULT_PAGE_SIZE);
  const [finalTableRowData, setFinalTableRowData] = useState([]);

  const { currency, currency_symbol } = tableData || {};
  const currencyObj = { code: currency, symbol: currency_symbol };

  useEffect(() => {
    const flattenedData =
      tableData && tableData.analytics && tableData.analytics.length
        ? tableData.analytics
        : [];

    setTableFlattenedData(
      sortAggregatedDataRow(flattenedData, sortBy, sortOrder),
    );
  }, [tableData, sortOrder, sortBy, currentPage, currentSize]);

  useEffect(() => {
    const indexOfLastPerm = currentPage * currentSize;
    const indexOfFirstPerm = indexOfLastPerm - currentSize;
    if (tableFlattenedData && tableFlattenedData.length) {
      const tableRows = [];
      const tableRowsForCSV = [];
      tableFlattenedData.forEach(item => {
        const revenueCell = getRevenueCell(item);
        tableRows.push({
          title: item.title,
          quantity: item.quantity,
          revenue: revenueCell,
          currency,
        });
        tableRowsForCSV.push({
          title: item.title,
          quantity: item.quantity,
          revenue: revenueCell,
          revenue_cent: item.revenue,
          currency,
        });
      });
      setFinalTableRowData(tableRows.slice(indexOfFirstPerm, indexOfLastPerm));

      const tableRowsWithoutCurrencySymbol = tableRowsForCSV.map(item => ({
        ...item,
        revenue: item.revenue.replace(/[^\d.]/g, ''), // Remove non-digit and non-decimal characters
      }));

      setTableFlattenedDataCSV(tableRowsWithoutCurrencySymbol);
    } else {
      setFinalTableRowData([]);
    }
  }, [tableFlattenedData, sortOrder, sortBy, currentPage, currentSize]);

  // HANDLERS
  const getRevenueCell = item => {
    const revenue = currency === 'USD' ? item.revenue_usd : item.revenue;
    return readablePrice(revenue, currencyObj);
  };
  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
    setCurrentPage(DEFAULT_PAGE_NO);
  };
  const getSortClass = field => {
    let sortClass = 'sc-sort';
    if (sortOrder === 'asc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-up';
    } else if (sortOrder === 'desc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-down';
    }
    return `cursor-pointer ${sortClass}`;
  };
  const getRevenueKey = () => {
    if (tableData && tableData.currency === 'USD') {
      return 'revenue_usd';
    }
    return 'revenue';
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
  };
  // RENDER
  const pagination = !itemBreakDownDataLoading ? (
    <SCPagination
      className="mt-4"
      total={tableData && tableData.analytics ? tableData.analytics.length : 0}
      page={currentPage}
      size={currentSize}
      sizes={DEFAULT_PAGE_SIZES}
      onPageChange={handlePageChange}
    />
  ) : null;

  const renderNoDataRow = (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        No data Found
      </td>
    </tr>
  );
  const tableHeaderBaseClass = [
    'bg-grey-lighter',
    'border-b',
    'capitalize p-3',
  ].join(' ');
  const tableHeaders = [
    `${currentTab && currentTab.title ? currentTab.title : ''} Name`,
    `Quantity Ordered`,
    'Revenue',
    'currency',
  ];
  const renderTableHeader = (
    <tr>
      <td className={`${tableHeaderBaseClass} border-r w-1/2`}>
        <div className="flex justify-between">
          <div className="flex">
            {tableHeaders[0]}
            <HelpPopover
              isPopoverOpen={isNameColumnHeaderIsOpen}
              position="right"
              isHtmlElement
              content={`Menu  ${currentTab.title}`}
              setIsPopoverOpen={setIsNameColumnHeaderIsOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          </div>
          <button
            type="button"
            className={getSortClass('title')}
            onClick={() => {
              handleSorting('title');
            }}
          >
            {/* button content */}
          </button>
        </div>
      </td>
      <td className={`${tableHeaderBaseClass} border-r`}>
        <div className="flex justify-between">
          <div className="flex">
            {tableHeaders[1]}
            <HelpPopover
              isPopoverOpen={isQuantityColumnHeaderIsOpen}
              position="right"
              isHtmlElement
              content={`${currentTab.title} Quantity is the total number of times the menu ${currentTab.title} has been ordered from`}
              setIsPopoverOpen={setIsQuantityColumnHeaderIsOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          </div>
          <button
            type="button"
            className={getSortClass('quantity')}
            onClick={() => {
              handleSorting('quantity');
            }}
          >
            {/* button content */}
          </button>
        </div>
      </td>
      <td className={`${tableHeaderBaseClass} `}>
        <div className="flex justify-between">
          <div className="flex">
            {tableHeaders[2]}
            <HelpPopover
              isPopoverOpen={isRevenueColumnHeaderIsOpen}
              position="right"
              isHtmlElement
              content={`The total gross sales for the ${currentTab.title}`}
              setIsPopoverOpen={setIsRevenueColumnHeaderIsOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          </div>
          <button
            type="button"
            className={getSortClass('revenue')}
            onClick={() => {
              handleSorting(getRevenueKey());
            }}
          >
            {/* button content */}
          </button>
        </div>
      </td>
    </tr>
  );
  const tableCellBaseClasses = ['p-4', 'border-b'].join(' ');
  const renderTableRow = () => {
    if (!finalTableRowData || !finalTableRowData.length) {
      return renderNoDataRow;
    }
    return finalTableRowData.map((item, index) => (
      <tr key={`${item.title}-${item.quantity}-${item.revenue}-${index + 1}`}>
        <td className={`${tableCellBaseClasses} border-r  w-1/2`}>
          {item.title}
        </td>
        <td className={`${tableCellBaseClasses} border-r text-right`}>
          {item.quantity}
        </td>
        <td className={`${tableCellBaseClasses} text-right`}>{item.revenue}</td>
      </tr>
    ));
  };
  const handleCsvDownload = () => {
    const tableHeaderForCsv = [...tableHeaders];

    const revenueIndex = tableHeaderForCsv.indexOf('Revenue');
    tableHeaderForCsv.splice(revenueIndex + 1, 0, 'Revenue (In Cents)');

    const CSVData = [{ ...tableHeaderForCsv }, ...tableFlattenedDataCSV];

    downloadCsvFromJSON(
      CSVData,
      `Analytics-Item-Visibility-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const renderDownloadCSVButton =
    finalTableRowData && finalTableRowData.length ? (
      <div
        className="absolute right-0 top-0"
        style={{
          marginTop: '20px',
          marginRight: '20px',
        }}
      >
        <SCButton
          label="Download"
          variant="primary-outline"
          size="sm"
          className="h-6 w-auto px-5 text-xs"
          action={handleCsvDownload}
        />
      </div>
    ) : null;
  return (
    <div className="bg-white w-full">
      {renderDownloadCSVButton}
      <div className="overflow-x-auto mb-8">
        <table className="table-auto w-full">
          <thead>{renderTableHeader}</thead>
          <tbody>{renderTableRow()}</tbody>
        </table>
      </div>
      {finalTableRowData && finalTableRowData.length ? pagination : null}
    </div>
  );
};
ItemBreakDownTable.propTypes = {
  tableData: PropTypes.any,
  currentTab: PropTypes.any,
  itemBreakDownDataLoading: PropTypes.bool,
};
export default ItemBreakDownTable;
