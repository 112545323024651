import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SCButton, SCPagination } from 'rollup-allspark';
import { format } from 'date-fns';
import {
  generateProviderSpecificTableData,
  sortProviderSpecificDataRow,
} from '../../../utils/nativeAnalyticsUtils';
import HelpPopover from '../../../components/HelpPopover';
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
} from '../../../config';
import { downloadCsvFromJSON } from '../../../utils/sheetUtils';
import { readablePrice, centToMoney } from '../../../utils/getCurrency';

const ItemBreakDownTableWithProvider = ({
  tableData,
  currentTab,
  analyticsProviders,
  itemBreakDownDataLoading,
}) => {
  // HOOKS
  const [isNameColumnHeaderIsOpen, setIsNameColumnHeaderIsOpen] =
    useState(false);
  const [isQuantityColumnHeaderIsOpen, setIsQuantityColumnHeaderIsOpen] =
    useState(false);
  const [isRevenueColumnHeaderIsOpen, setIsRevenueColumnHeaderIsOpen] =
    useState(false);
  const [tableFlattenedData, setTableFlattenedData] = useState([]);
  const [tableFlattenedDataCSV, setTableFlattenedDataCSV] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NO);
  const [currentSize, setCurrentSize] = useState(DEFAULT_PAGE_SIZE);
  const { currency, currency_symbol } = tableData || {};
  const currencyObj = { code: currency, symbol: currency_symbol };
  useEffect(() => {
    const indexOfLastPerm = currentPage * currentSize;
    const indexOfFirstPerm = indexOfLastPerm - currentSize;
    let paginatedData = [];
    let sortedData = [];

    const flattenedData = generateProviderSpecificTableData(
      tableData && tableData.analytics && tableData.analytics.length
        ? tableData.analytics
        : [],
    );

    if (
      flattenedData &&
      flattenedData.providerDataRow &&
      flattenedData.providerDataRow.length
    ) {
      sortedData = sortProviderSpecificDataRow(
        flattenedData,
        sortBy,
        sortOrder,
      );
      paginatedData = sortedData.providerDataRow.slice(
        indexOfFirstPerm,
        indexOfLastPerm,
      );
    }

    const paginatedFlattenedData = {
      ...flattenedData,
      providerDataRow: paginatedData,
    };

    setTableFlattenedDataCSV(sortedData);
    setTableFlattenedData(paginatedFlattenedData);
  }, [tableData, sortOrder, sortBy, currentPage, currentSize]);

  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
    setCurrentPage(DEFAULT_PAGE_NO);
  };
  const getSortClass = field => {
    let sortClass = 'sc-sort';
    if (sortOrder === 'asc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-up';
    } else if (sortOrder === 'desc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-down';
    }
    return `cursor-pointer ${sortClass}`;
  };
  const getRevenueKey = () => {
    if (tableData && tableData.currency === 'USD') {
      return 'revenue_usd';
    }
    return 'revenue';
  };
  const getProviderRevenueKey = () => {
    if (tableData && tableData.currency === 'USD') {
      return 'providerRevenuesInUsd';
    }
    return 'providerRevenues';
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
  };
  // RENDER
  const pagination = !itemBreakDownDataLoading ? (
    <SCPagination
      className="mt-4"
      total={tableData && tableData.analytics ? tableData.analytics.length : 0}
      page={currentPage}
      size={currentSize}
      sizes={DEFAULT_PAGE_SIZES}
      onPageChange={handlePageChange}
    />
  ) : null;
  const renderNoDataRow = (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        No data Found
      </td>
    </tr>
  );
  // TABLE HEADER
  const tableHeaderBaseClass = [
    'bg-grey-lighter',
    'border-b',
    'capitalize p-3',
  ].join(' ');
  // This tableHeaders will be used to render both the table header and the csv header
  const tableHeaders = ['', ` Quantity Ordered`, 'Revenue'];
  const renderTableHeader = (
    <tr>
      <th className={`${tableHeaderBaseClass} border-r`}>{tableHeaders[0]}</th>
      <th
        className={`${tableHeaderBaseClass} border-r`}
        colSpan={
          tableFlattenedData && Object.keys(tableFlattenedData).length
            ? tableFlattenedData.uniqueProvidersLength
            : 0
        }
      >
        <div className="flex justify-between">
          <div className="flex">
            {tableHeaders[1]}
            <HelpPopover
              isPopoverOpen={isQuantityColumnHeaderIsOpen}
              position="right"
              isHtmlElement
              content={`${currentTab.title} Quantity is the total number of times the menu ${currentTab.title} has been ordered from`}
              setIsPopoverOpen={setIsQuantityColumnHeaderIsOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          </div>
          <button
            type="button"
            className={getSortClass('quantity')}
            onClick={() => handleSorting('quantity')}
          >
            {/* button content */}
          </button>
        </div>
      </th>
      <th
        className={`${tableHeaderBaseClass}`}
        colSpan={
          tableFlattenedData && Object.keys(tableFlattenedData).length
            ? tableFlattenedData.uniqueProvidersLength
            : 0
        }
      >
        <div className="flex justify-between">
          <div className="flex">
            {tableHeaders[2]}
            <HelpPopover
              isPopoverOpen={isRevenueColumnHeaderIsOpen}
              position="right"
              isHtmlElement
              content={`The total gross sales for the ${currentTab.title}`}
              setIsPopoverOpen={setIsRevenueColumnHeaderIsOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          </div>
          <button
            type="button"
            className={getSortClass('revenue')}
            onClick={() => handleSorting(getRevenueKey())}
          >
            {/* button content */}
          </button>
        </div>
      </th>
    </tr>
  );
  // TABLE BODY
  const tableCellBaseClasses = ['p-4', 'border-b'].join(' ');
  const providerSpecificCells = (rowData, key) => {
    if (!rowData || !rowData[key]) {
      return null;
    }
    const cells = rowData[key];
    return cells.map((cell, index) => {
      if (
        key === 'providerQuantitiesTitle' ||
        key === 'providerRevenuesTitle'
      ) {
        const provider = analyticsProviders.find(p => p.id === cell);
        const showTitle = rowData[key].includes(cell);
        return (
          <td
            key={`${key}-${cell}-${index + 1}`}
            className={`${tableCellBaseClasses} border-r text-grey-darker text-right`}
          >
            <b>
              {showTitle && provider && provider.title ? provider.title : ''}
            </b>
          </td>
        );
      }
      return (
        <td
          key={`${key}-${cell}-${index + 1}`}
          className={`${tableCellBaseClasses} border-r text-right`}
        >
          {key === 'providerQuantities'
            ? cell
            : readablePrice(cell, currencyObj)}
        </td>
      );
    });
  };
  const renderTableRows = tableRowData => {
    if (!tableRowData) {
      return null;
    }
    const providerTitleRow = (
      <tr key="header">
        <td className={`${tableCellBaseClasses} border-r bg-grey-lighter`}>
          <div className="flex justify-between">
            <div className="flex">
              {currentTab && currentTab.title ? currentTab.title : ''} Name
              <HelpPopover
                isPopoverOpen={isNameColumnHeaderIsOpen}
                position="right"
                isHtmlElement
                content={`Menu  ${currentTab.title}`}
                setIsPopoverOpen={setIsNameColumnHeaderIsOpen}
                iconClassNames="sc-information cursor-pointer ml-1"
              />
            </div>
            <button
              type="button"
              className={getSortClass('title')}
              onClick={() => handleSorting('title')}
            >
              {/* button content */}
            </button>
          </div>
        </td>
        {providerSpecificCells(
          tableRowData.providerTitleRow,
          'providerQuantitiesTitle',
        )}
        {providerSpecificCells(
          tableRowData.providerTitleRow,
          'providerRevenuesTitle',
        )}
      </tr>
    );
    const providerDataRow = tableRowData.providerDataRow.map(row => (
      <tr key={row.title}>
        <td className={`${tableCellBaseClasses} border-r`}>{row.title}</td>
        {providerSpecificCells(row, 'providerQuantities')}
        {providerSpecificCells(row, getProviderRevenueKey())}
      </tr>
    ));
    return [providerTitleRow, ...providerDataRow];
  };
  const generateCSVArray = () => {
    // Generate Table Header
    const csvTableHeaderData = [tableHeaders[0]];
    let conditionMet = false;
    const addEmptyFields = (array, numFields) => {
      const arrayOfEmptyStrings = Array(numFields).fill('');
      array.push(
        tableHeaders[1],
        ...arrayOfEmptyStrings,
        tableHeaders[2],
        ...arrayOfEmptyStrings,
      );
    };
    tableHeaders.forEach(() => {
      if (!conditionMet) {
        const extraEmptyFieldsToAdd =
          tableFlattenedDataCSV.uniqueProvidersLength - 1;
        addEmptyFields(csvTableHeaderData, extraEmptyFieldsToAdd);
        conditionMet = true;
      }
    });

    csvTableHeaderData.push(
      Array((tableFlattenedDataCSV.uniqueProvidersLength - 1) * 2).fill(''),
    ); // Additional header cell for CENT VALUE
    csvTableHeaderData.push(' '); // Additional header cell for currency

    // Generate Provider Title Row
    const providerTitleRow = [
      `${currentTab && currentTab.title ? currentTab.title : ''} Name`,
    ];
    const addProviderTitles = (titlesArray, providerTitles) => {
      providerTitles.forEach(title => {
        const matchingProvider = analyticsProviders.find(
          provider => provider.id === title,
        );
        const providerTitle = matchingProvider ? matchingProvider.title : '';
        const providerRevenueTitle =
          providerTitles ===
          tableFlattenedDataCSV.providerTitleRow.providerRevenuesTitle
            ? [providerTitle, `${providerTitle} in Cents`]
            : providerTitle;
        if (Array.isArray(providerRevenueTitle)) {
          titlesArray.push(...providerRevenueTitle);
        } else {
          titlesArray.push(providerRevenueTitle);
        }
      });
    };
    addProviderTitles(
      providerTitleRow,
      tableFlattenedDataCSV.providerTitleRow.providerQuantitiesTitle,
    );
    addProviderTitles(
      providerTitleRow,
      tableFlattenedDataCSV.providerTitleRow.providerRevenuesTitle,
    );
    providerTitleRow.push('Currency');

    // generate Provider Data Row
    const providerDataRows = tableFlattenedDataCSV.providerDataRow.map(row => {
      const providerDataRow = [row.title];
      row.providerQuantities.forEach(quantity => {
        providerDataRow.push(quantity);
      });
      row[getProviderRevenueKey()].forEach(revenue => {
        providerDataRow.push(`${centToMoney(revenue)}`);
        providerDataRow.push(`${revenue}`);
      });
      providerDataRow.push(currency);
      return providerDataRow;
    });
    return [
      { ...csvTableHeaderData },
      { ...providerTitleRow },
      ...providerDataRows,
    ];
  };
  const handleCsvDownload = () => {
    const CSVData = generateCSVArray();
    downloadCsvFromJSON(
      CSVData,
      `Analytics-Item-Visibility-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };
  const renderDownloadCSVButton =
    tableData && tableData.analytics && tableData.analytics.length ? (
      <div
        className="absolute right-0 top-0"
        style={{
          marginTop: '20px',
          marginRight: '20px',
        }}
      >
        <SCButton
          label="Download"
          variant="primary-outline"
          size="sm"
          className="h-6 w-auto px-5 text-xs"
          action={handleCsvDownload}
        />
      </div>
    ) : null;

  return (
    <div className="bg-white w-full">
      {renderDownloadCSVButton}
      <div className="overflow-x-auto mb-8">
        <table className="table-auto w-full" id="item-breakdown-provider-table">
          <thead>{renderTableHeader}</thead>
          <tbody>
            {tableFlattenedData &&
            Object.keys(tableFlattenedData).length &&
            tableFlattenedData.providerDataRow.length
              ? renderTableRows(tableFlattenedData)[0]
              : renderNoDataRow}
            {tableFlattenedData &&
            Object.keys(tableFlattenedData).length &&
            tableFlattenedData.providerDataRow.length
              ? renderTableRows(tableFlattenedData).slice(1)
              : null}
            {!tableFlattenedData || Object.keys(tableFlattenedData).length === 0
              ? renderNoDataRow
              : null}
          </tbody>
        </table>
      </div>
      {tableFlattenedData &&
      Object.keys(tableFlattenedData).length &&
      tableFlattenedData.providerDataRow.length
        ? pagination
        : null}
    </div>
  );
};
ItemBreakDownTableWithProvider.propTypes = {
  tableData: PropTypes.any,
  currentTab: PropTypes.any,
  analyticsProviders: PropTypes.array,
  itemBreakDownDataLoading: PropTypes.bool,
};
export default ItemBreakDownTableWithProvider;
