/**
 *
 * LoadingButton
 *
 */

import React from 'react';
import { SCButton, SCLoader } from 'rollup-allspark';
import PropTypes from 'prop-types';

function LoadingButton({
  label,
  loading,
  disabled,
  variant,
  size,
  btnClass,
  action,
  visible,
}) {
  const loader = loading ? (
    <span id="loader">
      <SCLoader lineWidth={2} diameter={20} />
    </span>
  ) : null;

  return visible ? (
    <SCButton
      variant={variant}
      size={size}
      label={label}
      className={btnClass}
      type="submit"
      disabled={disabled || loading}
      action={action}
    >
      {loader}
    </SCButton>
  ) : null;
}

LoadingButton.defaultProps = {
  loading: false,
  disabled: false,
  variant: 'primary',
  size: 'lg',
  btnClass: 'text-sm',
  action: () => {},
  visible: true,
};

LoadingButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  btnClass: PropTypes.string,
  action: PropTypes.func,
  visible: PropTypes.bool,
};

export default LoadingButton;
