import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import RevenueBarChart from './RevenueBarChart';
import { BUSY_MODE_KEYS } from '../../../config/constants/analytics';

const BusyModeBarChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
}) => (
  <RevenueBarChart
    analyticsData={
      analyticsData && analyticsData.analytics && analyticsData.analytics.length
        ? analyticsData.analytics
        : []
    }
    analyticsDataLoading={analyticsDataLoading}
    selectedTimePeriod={selectedTimePeriod}
    targetKey={BUSY_MODE_KEYS.BUSY_MINUTES}
    seriesName="Busy Minutes"
  />
);

BusyModeBarChart.propTypes = {
  analyticsData: PropTypes.any,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectBusyModeAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectBusyModeAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(BusyModeBarChart);
