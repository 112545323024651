import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  ORDER_COUNT_KEYS,
  BUSY_MODE_KEYS,
} from '../../../config/constants/analytics';
import { centToMoney } from '../../../utils/getCurrency';

const Heatmap = ({
  id,
  data,
  currencySymbol,
  exportedCsvHeaderTitle,
  heatmapColorRanges,
  isComparisonEnabled,
  providerData,
  selectedProviderListForProviderComparison,
}) => {
  const isOrderCountChart = Object.values(ORDER_COUNT_KEYS).includes(id);
  const isBusyModeKey = Object.values(BUSY_MODE_KEYS).includes(id);

  const providersById = selectedProviderListForProviderComparison.reduce(
    (acc, obj) => {
      acc[obj.id] = obj;
      return acc;
    },
    {},
  );
  const options = {
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        export: {
          csv: {
            filename: id,
            headerCategory: exportedCsvHeaderTitle,
            headerValue: 'value',
          },
          svg: {
            filename: id,
          },
          png: {
            filename: id,
          },
        },
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: heatmapColorRanges,
        },
        radius: 0,
        useFillColorAsStroke: true,
      },
    },
    xaxis: {
      type: 'category',
      categories: Array.from({ length: 24 }, (_, i) => `${i}:00`),
    },
    tooltip: {
      y: {
        formatter(value) {
          return isOrderCountChart || isBusyModeKey
            ? value
            : `${currencySymbol}${value}`;
        },
        title: {
          formatter: sName => `${sName}`,
        },
      },

      items: {
        display: 'block',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      markers: {
        width: 15,
        height: 10,
        radius: 0,
        offsetY: -1,
        strokeWidth: 1,
        strokeColor: 'rgb(241 241 241)',
      },
      position: 'bottom',
    },
    stroke: {
      width: 0.5,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };
  // CREATE CUSTOM TOOLTIP FOR PROVIDER SPECIFIC DATA
  if (isComparisonEnabled && !isBusyModeKey) {
    options.tooltip.custom = function customTooltip({
      series,
      seriesIndex,
      dataPointIndex,
    }) {
      const xAxis =
        series[seriesIndex][dataPointIndex] === 0
          ? '0.00'
          : series[seriesIndex][dataPointIndex];
      let result = isOrderCountChart
        ? series[seriesIndex][dataPointIndex]
        : `${currencySymbol}${xAxis}`;

      result = `<p class="mb-3 flex justify-between"><b>Total:</b> ${result}</p>`;

      Object.entries(providerData).forEach(([providerId, item]) => {
        const providerKey = `${seriesIndex}-${dataPointIndex}`;
        const providerValue = item[providerKey];
        const sum = providerValue.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        );
        const finalValue = isOrderCountChart
          ? sum
          : `${currencySymbol}${centToMoney(sum)}`;

        result += `<small class="mb-2 flex justify-between"><b>${providersById[providerId].title}:</b> ${finalValue}</small>`; // Adding HTML tag for each provider
      });

      return `<div class="p-3" style="width:180px;">${result}</div>`;
    };
  }

  return (
    <div id="analytics-heatmap">
      <Chart options={options} series={data} type="heatmap" height="350" />
    </div>
  );
};

Heatmap.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  currencySymbol: PropTypes.string,
  exportedCsvHeaderTitle: PropTypes.string,
  heatmapColorRanges: PropTypes.any,
  isComparisonEnabled: PropTypes.bool,
  providerData: PropTypes.any,
  selectedProviderListForProviderComparison: PropTypes.array,
};

export default Heatmap;
