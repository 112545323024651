import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import { SCLoader } from 'rollup-allspark';
import { makeSelectProperty } from '../selectors';
import { cn, isEmpty } from '../../../utils';
import { getAnalyticsCurrencyObj } from '../../../utils/nativeAnalyticsUtils';
import { readablePrice } from '../../../utils/getCurrency';

const BrandPerformance = ({
  analyticsDataLoading,
  analyticsData,
  title,
  performanceData,
}) => {
  const currencyObj = getAnalyticsCurrencyObj(analyticsData);

  const { performanceRowData, grandTotalRevenue, grandTotalOrders } =
    performanceData;

  const thBaseClass =
    'p-3 bg-grey-lighter border-b border-r font-normal font-semibold text-left';

  if (analyticsDataLoading) {
    return <SCLoader lineWidth={5} diameter={50} />;
  }

  if (isEmpty(performanceRowData)) {
    return (
      <div className="flex justify-center items-center h-16 text-2xl text-grey-darker">
        No Data Found!
      </div>
    );
  }

  return (
    <div className="overflow-y-auto max-h-96">
      <table className="table-auto w-full">
        <thead className="sticky top-0">
          <tr>
            <th className={cn(thBaseClass)}>{title}</th>
            <th className={cn(thBaseClass, 'text-right')}>
              <div className="flex items-center justify-between">
                <span>Net Sales</span>
                <div>
                  <span className="text-xs mr-1 font-medium">Grand Total</span>
                  <span>{readablePrice(grandTotalRevenue, currencyObj)}</span>
                </div>
              </div>
            </th>
            <th className={cn(thBaseClass, 'text-right border-r-0')}>
              <div className="flex items-center justify-between">
                <span>Number of Orders</span>
                <div>
                  <span className="text-xs mr-1 font-medium">Total</span>
                  <span>{grandTotalOrders}</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {performanceRowData.map(row => (
            <tr key={row.name}>
              <td className="p-3 border-b border-r">{row.name}</td>
              <td className="p-3 text-right border-b border-r">
                {readablePrice(row.netSales, currencyObj)}
              </td>
              <td className="p-3 text-right border-b border-r-0">
                {row.totalOrder}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

BrandPerformance.propTypes = {
  analyticsDataLoading: PropTypes.bool,
  analyticsData: PropTypes.array,
  title: PropTypes.string,
  performanceData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: makeSelectProperty('analyticsDataLoading'),
  analyticsData: makeSelectProperty('analyticsData'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BrandPerformance);
