import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCButton, SCLoader, SCPagination } from 'rollup-allspark';
import { format } from 'date-fns';
import { formatDateExact } from '../../../utils/format-date';
import * as selectors from '../selectors';
import { useInjectSaga } from '../../../utils/injectSaga';
import saga from '../saga';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from '../reducer';
import HelpPopover from '../../../components/HelpPopover';
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
} from '../../../config';
import {
  generateBrandWiseBusyLogData,
  generatePauseStoreLogCSVData,
  sortAggregatedDataRow,
} from '../../../utils/nativeAnalyticsUtils';
import { downloadCsvFromJSON } from '../../../utils/sheetUtils';
import { isEmpty } from '../../../utils';
import { convertToHoursAndMinutes } from '../../../utils/numToTime';

const BusyModeLogTable = ({
  busyModeLogData,
  busyModeLogDataLoading,
  selectedBranch,
  brands,
}) => {
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });

  // CONSTANTS

  const headerKeys = [
    {
      title: 'Start time',
      key: 'start_time',
    },
    {
      title: 'End time',
      key: 'end_time',
    },
    {
      title: 'Brand',
      key: 'brand_id',
    },
    {
      title: 'Branch',
      key: 'branch_id',
    },
    {
      title: 'Busy min',
      key: 'busy_mins',
    },
  ];

  // HOOKS
  const [tableRowData, setTableRowData] = useState([]);
  const [isHeaderPopoverOpen, setIsHeaderPopoverOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NO);
  const [currentSize, setCurrentSize] = useState(DEFAULT_PAGE_SIZE);
  const [paginatedTableRowData, setPaginatedTableRowData] = useState([]);

  useEffect(() => {
    setSortOrder('desc');
    setSortBy('start_time');
    setCurrentPage(DEFAULT_PAGE_NO);
  }, []);

  useEffect(() => {
    const data =
      busyModeLogData && busyModeLogData.logs && busyModeLogData.logs.length
        ? busyModeLogData.logs
        : [];
    const brandWiseBusyLogData = generateBrandWiseBusyLogData(data);
    setTableRowData(
      sortAggregatedDataRow(brandWiseBusyLogData, sortBy, sortOrder),
    );
  }, [busyModeLogData, sortOrder, sortBy, currentPage, currentSize]);

  useEffect(() => {
    const indexOfLastPerm = currentPage * currentSize;
    const indexOfFirstPerm = indexOfLastPerm - currentSize;
    setPaginatedTableRowData(
      tableRowData.slice(indexOfFirstPerm, indexOfLastPerm),
    );
  }, [tableRowData, currentPage, currentSize, sortBy, sortOrder]);

  // HANDLES
  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
    setCurrentPage(DEFAULT_PAGE_NO);
  };
  const getSortClass = field => {
    let sortClass = 'sc-sort';
    if (sortOrder === 'asc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-up';
    } else if (sortOrder === 'desc' && sortBy === field) {
      sortClass = 'sc-keyboard-arrow-down';
    }
    return `cursor-pointer ${sortClass}`;
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
  };

  const handleCSVDownload = () => {
    const CSVData = generatePauseStoreLogCSVData(
      headerKeys,
      tableRowData,
      selectedBranch,
      brands,
    );
    downloadCsvFromJSON(
      CSVData,
      `paused-store-log-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  // RENDER

  // actionButton
  const renderDownloadCSVButton =
    tableRowData && tableRowData.length ? (
      <div className="absolute" style={{ top: 12, right: 18 }}>
        <SCButton
          label="Download"
          variant="primary-outline"
          size="sm"
          className="h-6 w-auto px-5 text-xs"
          action={handleCSVDownload}
        />
      </div>
    ) : null;

  // pagination
  const pagination =
    !busyModeLogDataLoading && tableRowData && tableRowData.length ? (
      <SCPagination
        className="mt-4"
        total={
          tableRowData && tableRowData.length > 0 ? tableRowData.length : 0
        }
        page={currentPage}
        size={currentSize}
        sizes={DEFAULT_PAGE_SIZES}
        onPageChange={handlePageChange}
      />
    ) : null;

  // loaders
  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  // tableHeader
  const renderTableHeaderEl = headerKeys.map((cell, index) => (
    <th
      key={`discount-header-cell-${index + 1}`}
      className="bg-grey-lighter border-b capitalize p-3"
    >
      <div className="flex justify-between">
        <div className="flex">
          {cell.title}
          {cell.key === 'busy_mins' ? (
            <HelpPopover
              isPopoverOpen={isHeaderPopoverOpen}
              position="top"
              isHtmlElement
              content="No of busy minutes of the pause store"
              setIsPopoverOpen={setIsHeaderPopoverOpen}
              iconClassNames="sc-information cursor-pointer ml-1"
            />
          ) : null}
        </div>
        {cell.key === 'start_time' || cell.key === 'end_time' ? (
          <button
            type="button"
            className={getSortClass(cell.key)}
            onClick={() => {
              handleSorting(cell.key);
            }}
          >
            {/* button content */}
          </button>
        ) : null}
      </div>
    </th>
  ));

  // tableBody
  const tableRowsEl = !isEmpty(paginatedTableRowData) ? (
    paginatedTableRowData.map(cell => {
      const {
        start_time: startTime,
        end_time: endTime,
        branch_id: branchId,
        busy_mins: busyMins,
        brand_id: brandId,
      } = cell;

      const matchingBranch = selectedBranch.find(
        branch => branch.id === branchId,
      );
      const branchTitle = matchingBranch ? matchingBranch.title : '';

      const matchingBrand = brands.find(brand => brand.id === brandId);

      const brandTitle = matchingBrand ? matchingBrand.title : '';

      return (
        <tr key={`busy-mode-row-${brandId * branchId + startTime + endTime}`}>
          <td className="p-4 border-r border-b text-xs">
            {formatDateExact(startTime)}
          </td>
          <td className="p-4 border-r border-b text-xs">
            {formatDateExact(endTime)}
          </td>
          <td className="p-4 border-r border-b">{brandTitle}</td>
          <td className="p-4 border-r border-b">{branchTitle}</td>
          <td className="p-4 border-r border-b">
            {convertToHoursAndMinutes(busyMins) || '0m'}
          </td>
        </tr>
      );
    })
  ) : (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        No data Found
      </td>
    </tr>
  );

  // table
  const renderTable = (
    <div>
      {renderDownloadCSVButton}
      <div className="h-64 max-h-full overflow-y-auto mb-4">
        <table className="table-auto w-full">
          <thead>
            <tr>{renderTableHeaderEl}</tr>
          </thead>
          <tbody>{tableRowsEl}</tbody>
        </table>
      </div>

      {tableRowData && tableRowData.length ? pagination : null}
    </div>
  );

  return busyModeLogDataLoading ? renderLoader : renderTable;
  // Tabs
};

BusyModeLogTable.propTypes = {
  busyModeLogData: PropTypes.any,
  busyModeLogDataLoading: PropTypes.bool,
  selectedBranch: PropTypes.any,
  brands: PropTypes.array,
};
const mapStateToProps = createStructuredSelector({
  busyModeLogData: selectors.makeSelectBusyModeLogData(),
  busyModeLogDataLoading: selectors.makeSelectBusyModeLogDataLoading(),
  selectedBranch: selectors.makeSelectAnalyticsSelectedBranch(),
  brands: selectors.makeSelectProperty('brands'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BusyModeLogTable);
