import {
  addMinutes,
  addSeconds,
  areIntervalsOverlapping,
  endOfDay,
  format,
  formatISO,
  roundToNearestMinutes,
  startOfDay,
  subDays,
} from 'date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import { isDate } from 'date-fns/fp';
import { ADDITIONAL_TIME_FOR_GO_LIVE_DATE } from '../config/env';
import { isEmpty } from './index';

export const formatDate = (dateStr, formatStr = 'dd MMM, yyyy hh:mm a') => {
  const formattedDate = dateStr ? new Date(dateStr) : new Date();
  return format(formattedDate, formatStr);
};

export const formatDateUTC = (dateStr, formatStr = 'dd MMM, yyyy hh:mm a') => {
  const formattedDate =
    dateStr && dateStr.length ? new Date(dateStr) : new Date();
  const utcDate = addMinutes(formattedDate, formattedDate.getTimezoneOffset());
  return format(utcDate, formatStr);
};

export const formatDateWithoutTimezone = (
  dateStr,
  formatStr = 'dd MMM, yyyy hh:mm a',
) => {
  const match = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})/);
  if (match) {
    const [, year, month, day] = match;
    const dateWithoutTime = new Date(`${year}-${month}-${day}T00:00:00Z`);
    return format(dateWithoutTime, formatStr);
  }
  return ''; // Handle invalid input gracefully
};

export const subtractDays = (date, noOfDays = 1) =>
  startOfDay(subDays(new Date(date), noOfDays));

export const getStartOfWeek = (date = new Date()) =>
  startOfWeek(date, {
    weekStartsOn: 1,
  });

export const getStartOfDay = (date = new Date()) => startOfDay(new Date(date));
export const getEndOfDay = (date = new Date()) => endOfDay(new Date(date));

export const formatDateExact = dateStr => {
  const year = dateStr.substr(0, 4);
  const month = dateStr.substr(5, 2);
  const day = dateStr.substr(8, 2);
  const hours = dateStr.substr(11, 2);
  const minutes = dateStr.substr(14, 2);
  const seconds = dateStr.substr(17, 2);

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const getSecondsFromMilitaryTime = time => {
  const militaryTimeString = time.toString().padStart(4, '0');
  const hours = parseInt(militaryTimeString.substring(0, 2), 10);
  const minutes = parseInt(militaryTimeString.substring(2, 4), 10);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes * 60;
};

export const getTomorrow = () =>
  new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

export const getMergedUTCDateMilitaryTime = (date = '', militaryTime = 0) => {
  const utcDate = startOfDay(new Date(date));
  const seconds = getSecondsFromMilitaryTime(militaryTime);
  const finalDate = addSeconds(utcDate, seconds);
  return finalDate;
};

export const getISOStringFromDateTime = (date, militaryTime) => {
  const mergedDateTime = getMergedUTCDateMilitaryTime(date, militaryTime);
  return formatISO(mergedDateTime);
};

export const getDateTimeDuratedFromCurrentTime = (duration = 0, round = 0) => {
  const currentDateTime = new Date();
  let duratedTime = addMinutes(currentDateTime, duration);

  if (round) {
    duratedTime = roundToNearestMinutes(duratedTime, {
      roundingMethod: 'ceil',
      nearestTo: round,
    });
  }

  return duratedTime;
};

export const getNumTimeDuratedFromCurrentTime = (duration = 0, round = 0) => {
  let duratedTime = getDateTimeDuratedFromCurrentTime(duration);
  if (round) {
    duratedTime = roundToNearestMinutes(duratedTime, {
      roundingMethod: 'ceil',
      nearestTo: round,
    });
  }
  const currentHours = duratedTime.getHours().toString().padStart(2, '0');
  const currentMinutes = duratedTime.getMinutes().toString().padStart(2, '0');
  const mergedDateTime = parseInt(currentHours + currentMinutes, 10);

  return mergedDateTime || 0;
};

export const areDateRangesOverlapped = (dateRanges = []) =>
  dateRanges.some((range1, i) => {
    if (!range1?.from || !range1?.to) return false;

    return dateRanges.slice(i + 1).some(range2 => {
      if (!range2?.from || !range2?.to) return false;

      return areIntervalsOverlapping(
        {
          start: range1?.from,
          end: range1?.to,
        },
        {
          start: range2?.from,
          end: range2?.to,
        },
      );
    });
  });

export const filterPassedTime = (selectedTime = null) =>
  Date.now() + ADDITIONAL_TIME_FOR_GO_LIVE_DATE <
  new Date(selectedTime).getTime();

export const getDateInstance = date =>
  !isEmpty(date) && !isDate(date) ? new Date(date) : date;
