export const AGGREGATORS = {
  DETAILS: {
    method: 'GET',
    reducerKey: 'aggregatorDetails',
    url: (brandId, branchId, providerId) =>
      `v1/brand-provider/brand/${brandId}/branch/${branchId}/provider/${providerId}`,
  },
  CREATE: {
    method: 'POST',
    url: 'v1/brand-provider',
  },
  UPDATE: {
    method: 'PATCH',
    url: IntegrationId => `v1/brand-provider/${IntegrationId}`,
  },
  DELETE: {
    method: 'DELETE',
    url: IntegrationId => `v1/brand-provider/${IntegrationId}`,
  },
  UBEREATS: {
    LOGIN_URL: {
      reducerKey: 'uberEatsLoginUrl',
      url: 'v1/klikber/store',
    },
    STORE_LIST: {
      reducerKey: 'uberEatsStoreList',
      url: (brandId, branchId) =>
        `v1/klikber/stores/${branchId}?brand_id=${brandId}`,
    },
  },
  SHOPEE: {
    LOGIN: {
      reducerKey: 'shopeeLoginCredentials',
      url: 'v1/shopee/interceptor/login',
      method: 'POST',
    },
    VERIFY_TOKEN: {
      url: 'v1/shopee/interceptor/otp',
      method: 'POST',
    },
  },
  SQUARE: {
    LOGIN_URL: {
      reducerKey: 'squareLoginUrl',
      url: '/v1/squareit/store',
      method: 'POST',
    },
    STORE_LIST: {
      reducerKey: 'squareStoreList',
      url: (brandId, branchId) =>
        `v1/squareit/stores/${branchId}?brand_id=${brandId}`,
      method: 'GET',
    },
  },
  GOFOOD: {
    SEND_OTP: {
      reducerKey: 'goFoodOTP',
      url: 'v1/gofood/interceptor/login',
      method: 'POST',
    },
    VERIFY_OTP: {
      url: 'v1/gofood/interceptor/token',
      method: 'POST',
    },
    LOGIN_URL: {
      reducerKey: 'goFoodLoginUrl',
      url: `v1/klikjek/store`,
    },
    STORE_LIST: {
      reducerKey: 'goFoodStoreList',
      url: branchId => `v1/klikjek/stores/${branchId}`,
    },
  },
  DEMAECAN: {
    LOGIN: {
      reducerKey: 'loginDemaecan',
      url: 'v1/demaecan/interceptor/login',
      method: 'POST',
    },
  },
  TIKTOK: {
    LOGIN_URL: {
      reducerKey: 'tiktokLoginUrl',
      url: 'v1/kliktok/store',
      method: 'POST',
    },
    AUTH_CALLBACK: {
      url: 'v1/kliktok/authcallback',
      method: 'GET',
    },
    SHOP_LIST: {
      reducerKey: 'tiktokShopList',
      url: (brandId, branchId) =>
        `v1/kliktok/stores/${branchId}?brand_id=${brandId}`,
      method: 'GET',
    },
  },
  UBERDIRECT: {
    CREATE: {
      url: 'v1/fulfilment/partner/org',
      method: 'POST',
    },
    GET: {
      url: 'v1/fulfilment/partner/org',
      method: 'GET',
      reducerKey: 'uberDirectDetails',
    },
  },
};

export default {
  AGGREGATORS,
};
