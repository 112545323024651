import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCLoader } from 'rollup-allspark';
import { ITEM_TABLE_BREAKDOWN_HELP } from '../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { ITEM_BREAKDOWN_TABS } from '../../../config/constants/analytics';
import HelpPopover from '../../../components/HelpPopover';
import ItemBreakDownTable from './ItemBreakDownTable';
import * as selectors from '../selectors';
import { loadItemBreakdownData } from '../actions';
import ItemBreakDownTableWithProvider from './ItemBreakDownTableWithProvider';
const ItemBreakDown = ({
  handleItemVisibilityTab,
  filterData,
  getItemBreakdownData,
  itemBreakDownData,
  itemBreakDownDataLoading,
  isComparisonEnabled,
  analyticsProviders,
  selectedBusiness,
}) => {
  // CONSTANTS
  const ITEM_TABS = ITEM_BREAKDOWN_TABS.map(tab => {
    switch (tab.title) {
      case 'items':
        return { id: tab.id, title: 'Item' };
      case 'categories':
        return { id: tab.id, title: 'Category' };
      case 'modifiers':
        return { id: tab.id, title: 'Modifier' };
      default:
        return { id: tab.id, title: 'Item' };
    }
  });

  // HOOKS
  const [isItemBreakdownPopoverOpen, setIsItemBreakdownPopoverOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(ITEM_TABS[0]);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(itemBreakDownData || null);
  }, [itemBreakDownData]);

  // HANDLERS
  const getFilteredItemBreakdownData = id => {
    const params = { ...filterData };
    params.activeTab = id;
    getItemBreakdownData(params);
  };
  const handleChangeTab = tab => {
    if (selectedBusiness) {
      handleItemVisibilityTab(tab.id);
      setSelectedTab(tab);
      getFilteredItemBreakdownData(tab.id);
    }
  };
  // RENDERS
  // Tabs
  const renderTabs = () =>
    ITEM_TABS.map(ct => {
      const selectedClassName =
        selectedTab.id === ct.id
          ? 'border-b-2 border-primary bg-primary-lighter text-black'
          : 'text-grey-darker';
      return (
        <div
          key={ct.id}
          role="presentation"
          className={`p-3 w-32 text-center mr-3 cursor-pointer ${selectedClassName}`}
          onClick={() => handleChangeTab(ct)}
        >
          {ct.title}
        </div>
      );
    });
  // loading placeholder
  const renderLoadingPlaceholder = (
    <div className="w-full h-20">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );
  // AggregatedTable
  const aggregatedTable = itemBreakDownDataLoading ? (
    renderLoadingPlaceholder
  ) : (
    <ItemBreakDownTable
      tableData={tableData}
      currentTab={selectedTab}
      itemBreakDownDataLoading={itemBreakDownDataLoading}
    />
  );
  // providerWiseTable
  const providerWiseTable = itemBreakDownDataLoading ? (
    renderLoadingPlaceholder
  ) : (
    <ItemBreakDownTableWithProvider
      tableData={tableData}
      currentTab={selectedTab}
      analyticsProviders={analyticsProviders}
      itemBreakDownDataLoading={itemBreakDownDataLoading}
    />
  );
  const renderTable = isComparisonEnabled ? providerWiseTable : aggregatedTable;
  return (
    <div className="flex flex-col p-4 w-full justify-between relative">
      <div className="flex flex-col text-black mb-2">
        <h5 className="text-base font-medium capitalize mb-2 flex">
          Menu Category, Item, and Modifier Report
          <HelpPopover
            isPopoverOpen={isItemBreakdownPopoverOpen}
            position="right"
            isHtmlElement
            content={ITEM_TABLE_BREAKDOWN_HELP}
            setIsPopoverOpen={setIsItemBreakdownPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </h5>
        {/* <div className="text-sm mb-3">
          The list of items defined filter type and time period
        </div> */}
      </div>
      <div className="flex items-center mb-4">{renderTabs()}</div>
      {renderTable}
    </div>
  );
};
ItemBreakDown.propTypes = {
  handleItemVisibilityTab: PropTypes.func,
  filterData: PropTypes.object,
  getItemBreakdownData: PropTypes.func,
  itemBreakDownData: PropTypes.any,
  itemBreakDownDataLoading: PropTypes.bool,
  isComparisonEnabled: PropTypes.bool,
  analyticsProviders: PropTypes.array,
  selectedBusiness: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
  itemBreakDownData: selectors.makeSelectItemBreakdownData(),
  itemBreakDownDataLoading: selectors.makeSelectItemBreakDownDataLoading(),
  isComparisonEnabled: selectors.makeSelectComparisonEnabled(),
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  selectedBusiness: selectors.makeSelectAnalyticsSelectedBusiness(),
});
function mapDispatchToProps(dispatch) {
  return {
    getItemBreakdownData: params => dispatch(loadItemBreakdownData(params)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ItemBreakDown);
