import React from 'react';
import PropTypes from 'prop-types';
import { SCLoader } from 'rollup-allspark';
import {
  exportedCsvHeaderTitleForXAxis,
  getCumulativeSumForDailyData,
  getCumulativeSumForMonthlyData,
  getCumulativeSumForWeeklyData,
  findDateKey,
} from '../../../utils/nativeAnalyticsUtils';
import BarChart from './BarChart';
import {
  MONTHLY_TIME_PERIOD,
  REVENUE_KEYS,
  WEEKLY_TIME_PERIOD,
  AVG_BASKET_KEYS,
  DEFAULT_CURRENCY_OBJ,
} from '../../../config/constants/analytics';
import {
  formatDate,
  getStartOfWeek,
  formatDateWithoutTimezone,
} from '../../../utils/format-date';

const RevenueBarChart = ({
  analyticsData,
  analyticsDataLoading,
  targetKey,
  seriesName,
  selectedTimePeriod,
}) => {
  const currencyCodeList =
    analyticsData && analyticsData.length
      ? analyticsData.map(b => b.currency)
      : [];

  const branchCurrencyObj =
    analyticsData && analyticsData.length
      ? {
          code: analyticsData[0].currency,
          symbol: analyticsData[0].currency_symbol,
        }
      : '';

  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const currencyObj = shouldShowUsd ? DEFAULT_CURRENCY_OBJ : branchCurrencyObj;
  /**
   * Returns the cumulative sum of the analytics data for the selected time period and target key.
   * @returns {Array} The cumulative sum of the analytics data.
   */
  const getCumulativeSumForSelectedTimePeriod = () => {
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD)
      return getCumulativeSumForWeeklyData(analyticsData, targetKey);
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD)
      return getCumulativeSumForMonthlyData(analyticsData, targetKey);
    return getCumulativeSumForDailyData(analyticsData, targetKey);
  };

  const cumulativeRevenue = getCumulativeSumForSelectedTimePeriod() || [];

  const isRevenueKey = Object.values(REVENUE_KEYS).includes(targetKey);
  const isAvgBasketsKey = Object.values(AVG_BASKET_KEYS).includes(targetKey);

  /**
   * Prepares the x-axis data for the bar chart based on the selected time period.
   * @param {Object} data - The data object to be formatted.
   * @returns {string} The formatted date string for the x-axis label.
   */
  const prepareXaxisData = data => {
    const dateKey = findDateKey([data]);
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD) {
      const reportDate = new Date(data[dateKey]);
      const wStartDate = getStartOfWeek(reportDate);
      return formatDate(wStartDate.toISOString(), `MMM dd ''yy`);
    }
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD) {
      return formatDate(data[dateKey], `MMM ''yy`);
    }
    return formatDateWithoutTimezone(data[dateKey], `MMM dd`);
  };

  /**
   * Returns the exported CSV header title for the selected time period.
   * @returns {string} The exported CSV header title.
   */
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);

  const xAxisData = cumulativeRevenue.map(prepareXaxisData) || [];
  const yAxisData =
    cumulativeRevenue.map(d =>
      isRevenueKey || isAvgBasketsKey ? (d.count / 100).toFixed(2) : d.count,
    ) || [];

  const renderGrossRevenueChart = () => {
    if (analyticsDataLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <SCLoader lineWidth={5} diameter={50} />
        </div>
      );
    }

    if (analyticsData && analyticsData.length) {
      return (
        <BarChart
          id={targetKey}
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          seriesName={seriesName}
          currencyObj={currencyObj}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
        />
      );
    }
    return (
      <div className="flex justify-center items-center h-full text-2xl text-grey-darker">
        No Data Found!
      </div>
    );
  };

  return renderGrossRevenueChart();
};

RevenueBarChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  targetKey: PropTypes.string,
  selectedTimePeriod: PropTypes.string,
};
export default RevenueBarChart;
