import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import { BUSY_MODE_KEYS } from '../../../config/constants/analytics';
import AnalyticsHeatmapContainer from './AnalyticsHeatmapContainer';

const BusyModeHeatmapChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
  isComparisonEnabled,
}) => {
  const renderChart = (
    <AnalyticsHeatmapContainer
      analyticsData={
        analyticsData &&
        analyticsData.analytics &&
        analyticsData.analytics.length
          ? analyticsData.analytics
          : []
      }
      analyticsDataLoading={analyticsDataLoading}
      selectedTimePeriod={selectedTimePeriod}
      targetKey={BUSY_MODE_KEYS.BUSY_MINUTES}
      seriesName="Realized Revenue"
      isComparisonEnabled={isComparisonEnabled}
    />
  );
  return <div className="flex flex-col p-4 h-full">{renderChart}</div>;
};
BusyModeHeatmapChart.propTypes = {
  analyticsData: PropTypes.any,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isComparisonEnabled: selectors.makeSelectComparisonEnabled(),
  analyticsDataLoading: selectors.makeSelectBusyModeAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectBusyModeAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(BusyModeHeatmapChart);
