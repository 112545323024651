export const LOAD_SUMMARY = 'app/NativeAnalytics/LOAD_SUMMARY';
export const LOAD_SUMMARY_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_SUMMARY_SUCCESSFUL';
export const LOAD_SUMMARY_FAILED = 'app/NativeAnalytics/LOAD_SUMMARY_FAILED';

export const LOAD_ANALYTICS_DATA = 'app/NativeAnalytics/LOAD_ANALYTICS_DATA';
export const LOAD_ANALYTICS_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ANALYTICS_DATA_SUCCESSFUL';
export const LOAD_ANALYTICS_DATA_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_DATA_FAILED';

export const LOAD_ORDERS_DATA = 'app/NativeAnalytics/LOAD_ORDERS_DATA';
export const LOAD_ORDERS_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ORDERS_DATA_SUCCESSFUL';
export const LOAD_ORDERS_DATA_FAILED =
  'app/NativeAnalytics/LOAD_ORDERS_DATA_FAILED';

// BUSINESSES
export const LOAD_ANALYTICS_BUSINESS =
  'app/NativeAnalytics/LOAD_ANALYTICS_BUSINESS';
export const LOAD_ANALYTICS_BUSINESS_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ANALYTICS_BUSINESS_SUCCESSFUL';
export const LOAD_ANALYTICS_BUSINESS_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_BUSINESS_FAILED';
export const CHANGE_ANALYTICS_BUSINESS =
  'app/NativeAnalytics/CHANGE_ANALYTICS_BUSINESS';

// BRANDS
export const LOAD_ANALYTICS_BRANDS =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANDS';
export const LOAD_ANALYTICS_BRANDS_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANDS_SUCCESSFUL';
export const LOAD_ANALYTICS_BRANDS_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANDS_FAILED';
export const CHANGE_ANALYTICS_BRAND =
  'app/NativeAnalytics/CHANGE_ANALYTICS_BRAND';

// BRANCHES
export const LOAD_ANALYTICS_BRANCHES =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANCHES';
export const LOAD_ANALYTICS_BRANCHES_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANCHES_SUCCESSFUL';
export const LOAD_ANALYTICS_BRANCHES_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_BRANCHES_FAILED';
export const CHANGE_ANALYTICS_BRANCH =
  'app/NativeAnalytics/CHANGE_ANALYTICS_BRANCH';

// PROVIDERS
export const LOAD_ANALYTICS_PROVIDERS =
  'app/NativeAnalytics/LOAD_ANALYTICS_PROVIDERS';
export const LOAD_ANALYTICS_PROVIDERS_SUCCESS =
  'app/NativeAnalytics/LOAD_ANALYTICS_PROVIDERS_SUCCESS';
export const LOAD_ANALYTICS_PROVIDERS_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_PROVIDERS_FAILED';
export const CHANGE_ANALYTICS_PROVIDER =
  'app/NativeAnalytics/CHANGE_ANALYTICS_PROVIDER';

export const CHANGE_ANALYTICS_START_DATE =
  'app/NativeAnalytics/CHANGE_ANALYTICS_START_DATE';
export const CHANGE_ANALYTICS_END_DATE =
  'app/NativeAnalytics/CHANGE_ANALYTICS_END_DATE';

export const CHANGE_ANALYTICS_TIME_PERIOD =
  'app/NativeAnalytics/CHANGE_ANALYTICS_TIME_PERIOD';
export const CHANGE_TIME_PERIOD_FILTER_STATUS =
  'app/NativeAnalytics/CHANGE_TIME_PERIOD_FILTER_STATUS';

export const CHANGE_COMPARISON_TOGGLE =
  'app/NativeAnalytics/CHANGE_COMPARISON_TOGGLE';
export const UPDATE_PAGINATION = 'app/NativeAnalytics/UPDATE_PAGINATION';

// CSV
export const LOAD_ANALYTICS_CSV = 'app/NativeAnalytics/LOAD_ANALYTICS_CSV';
export const LOAD_ANALYTICS_CSV_SUCCESS =
  'app/NativeAnalytics/LOAD_ANALYTICS_CSV_SUCCESS';
export const LOAD_ANALYTICS_CSV_FAILED =
  'app/NativeAnalytics/LOAD_ANALYTICS_CSV_FAILED';

// STATUS

export const LOAD_STATUS = 'app/NativeAnalytics/LOAD_STATUS';
export const LOAD_STATUS_SUCCESS = 'app/NativeAnalytics/LOAD_STATUS_SUCCESS';
export const LOAD_STATUS_FAILED = 'app/NativeAnalytics/LOAD_STATUS_FAILED';

export const CHANGE_IS_LOADED_FIRST_TIME =
  'app/NativeAnalytics/CHANGE_IS_LOADED_FIRST_TIME';
export const CHANGE_TIME_PERIOD_SLUG_FOR_DATA_CALCULATION =
  'app/NativeAnalytics/CHANGE_TIME_PERIOD_SLUG_FOR_DATA_CALCULATION';

export const CHANGE_SELECTED_PROVIDERS_LIST_FOR_PROVIDER_COMPARISON =
  'app/NativeAnalytics/CHANGE_SELECTED_PROVIDERS_LIST_FOR_PROVIDER_COMPARISON';

export const RESET_ANALYTICS_DATA = 'app/NativeAnalytics/RESET_ANALYTICS_DATA';

export const LOAD_ITEM_BREAKDOWN_DATA =
  'app/NativeAnalytics/LOAD_ITEM_BREAKDOWN_DATA';
export const LOAD_ITEM_BREAKDOWN_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_ITEM_BREAKDOWN_DATA_SUCCESSFUL';
export const LOAD_ITEM_BREAKDOWN_DATA_FAILED =
  'app/NativeAnalytics/LOAD_ITEM_BREAKDOWN_DATA_FAILED';

export const CHANGE_OWN_CHANNEL = 'app/NativeAnalytics/CHANGE_OWN_CHANNEL';

export const LOAD_BUSY_MODE_ANALYTICS_DATA =
  'app/NativeAnalytics/LOAD_BUSY_MODE_ANALYTICS_DATA';
export const LOAD_BUSY_MODE_ANALYTICS_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_BUSY_MODE_ANALYTICS_DATA_SUCCESSFUL';
export const LOAD_BUSY_MODE_ANALYTICS_DATA_FAILED =
  'app/NativeAnalytics/LOAD_BUSY_MODE_ANALYTICS_DATA_FAILED';

export const LOAD_BUSY_MODE_LOG_DATA =
  'app/NativeAnalytics/LOAD_BUSY_MODE_LOG_DATA';
export const LOAD_BUSY_MODE_LOG_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_BUSY_MODE_LOG_DATA_SUCCESSFUL';
export const LOAD_BUSY_MODE_LOG_DATA_FAILED =
  'app/NativeAnalytics/LOAD_BUSY_MODE_LOG_DATA_FAILED';

export const LOAD_PAYMENT_METHOD_DATA =
  'app/NativeAnalytics/LOAD_PAYMENT_METHOD_DATA';
export const LOAD_PAYMENT_METHOD_DATA_SUCCESSFUL =
  'app/NativeAnalytics/LOAD_PAYMENT_METHOD_DATA_SUCCESSFUL';
export const LOAD_PAYMENT_METHOD_DATA_FAILED =
  'app/NativeAnalytics/LOAD_PAYMENT_METHOD_DATA_FAILED';

export const CHANGE_MORE_THAN_30_DAYS_STATUS =
  'app/NativeAnalytics/CHANGE_MORE_THAN_30_DAYS_STATUS';

export const CHANGE_COMPARISON_TYPE =
  'app/NativeAnalytics/CHANGE_COMPARISON_TYPE';

// OOS
export const GET_LIST = 'app/NativeAnalytics/GET_LIST';
export const GET_LIST_SUCCESS = 'app/NativeAnalytics/GET_LIST_SUCCESS';
export const GET_LIST_FAILED = 'app/NativeAnalytics/GET_LIST_FAILED';

export const GET_DATA = 'app/NativeAnalytics/GET_DATA';
export const GET_DATA_SUCCESS = 'app/NativeAnalytics/GET_DATA_SUCCESS';
export const GET_DATA_FAILED = 'app/NativeAnalytics/GET_DATA_FAILED';

export const EDIT_FIELD = 'app/NativeAnalytics/EDIT_FIELD';

// constants for changing any entity
export const CHANGE_ENTITY = 'app/AddOrderConfig/CHANGE_ENTITY';
