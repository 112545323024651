/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DEFAULT_ALL_PAGE_SIZE, DEFAULT_PAGE_NO } from '../../config';
import { APP_SAGA } from '../../config/constants/app';
import {
  AGGREGATORS_TIKTOK_REDIRECT_ROUTE,
  RATING_INTEGRATION_FACEBOOK_REDIRECT_ROUTE,
} from '../../config/constants/routes';
import routes from '../../routes';
import history from '../../utils/history';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import TiktokRedirect from '../Integration/IntegrationForms/Tiktok/RedirectPage';
import NotFoundPage from '../NotFoundPage/Loadable';
import { loadOrderSourceGroupList, loadProviders } from '../ONI/actions';
import oniReducer from '../ONI/reducer';
import oniSaga from '../ONI/saga';
import PrivateRoute from '../PrivateRoute';
import FacebookRedirect from '../RatingAndReview/IntegrationForm/Facebook/FacebookRedirect';
import { getGlobalList } from './action';
import appReducer from './reducer';
import appSaga from './saga';
import { makeSelectAccessToken } from './selectors';

const App = ({
  getProviders,
  getOrderSourceGroupList,
  getBusinessList,
  getCountries,
  getCurrencyList,
  accessToken,
}) => {
  useInjectReducer({ key: 'oni', reducer: oniReducer });
  useInjectSaga({ key: 'oni', saga: oniSaga });

  useInjectReducer({ key: 'app', reducer: appReducer });
  useInjectSaga({ key: 'app', saga: appSaga });

  useEffect(() => {
    if (accessToken) {
      getProviders();
      getOrderSourceGroupList();
      getBusinessList({
        url: APP_SAGA.BUSINESS_LIST.GET,
        reducerKey: APP_SAGA.BUSINESS_LIST.KEY,
        params: {
          page: DEFAULT_PAGE_NO,
          size: DEFAULT_ALL_PAGE_SIZE,
        },
      });
      getCurrencyList({
        url: APP_SAGA.CURRENCY_LIST.GET,
        reducerKey: APP_SAGA.CURRENCY_LIST.KEY,
      });
      getCountries({
        url: APP_SAGA.COUNTRY_LIST.GET,
        reducerKey: APP_SAGA.COUNTRY_LIST.KEY,
      });
    }
  }, [accessToken]);

  return (
    <div>
      <BrowserRouter history={history}>
        <Routes>
          {routes.map(route => (
            <Route
              path={route.path}
              element={
                <PrivateRoute path={route.path} element={<route.main />} />
              }
              key={`route_index_${Math.random()}`}
              errorElement={<div>Error in Route!</div>}
            ></Route>
          ))}
          <Route
            path={RATING_INTEGRATION_FACEBOOK_REDIRECT_ROUTE}
            element={<FacebookRedirect />}
          />
          <Route
            path={AGGREGATORS_TIKTOK_REDIRECT_ROUTE}
            element={<TiktokRedirect />}
          />
          <Route component={NotFoundPage} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

App.propTypes = {
  getProviders: PropTypes.func,
  accessToken: PropTypes.string,
  getOrderSourceGroupList: PropTypes.func,
  getBusinessList: PropTypes.func,
  getCurrencyList: PropTypes.func,
  getCountries: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  accessToken: makeSelectAccessToken(),
});

const mapDispatchToProps = dispatch => ({
  getProviders: () => dispatch(loadProviders()),
  getOrderSourceGroupList: () => dispatch(loadOrderSourceGroupList()),
  getBusinessList: params => dispatch(getGlobalList(params)),
  getCurrencyList: params => dispatch(getGlobalList(params)),
  getCountries: params => dispatch(getGlobalList(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
