import { DEFAULT_ALL_PAGE_SIZE, DEFAULT_PAGE_NO } from '../../config';
import * as constants from './constants';

const defaultParams = {
  page: DEFAULT_PAGE_NO,
  size: DEFAULT_ALL_PAGE_SIZE,
};

export function loadStatus() {
  return {
    type: constants.LOAD_STATUS,
  };
}

export function deselectBrands() {
  return {
    type: constants.DESELECT_BRANDS,
  };
}

export function loadBrands(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: constants.LOAD_ONI_BRAND,
    params,
  };
}

export function loadBranches(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: constants.LOAD_ONI_BRANCH,
    params,
  };
}

export function changeBrand(brand) {
  return {
    type: constants.CHANGE_BRAND,
    brand,
  };
}

export function changeBranch(branch) {
  return {
    type: constants.CHANGE_BRANCH,
    branch,
  };
}

export function loadProviders(params) {
  return {
    type: constants.LOAD_PROVIDERS,
    params,
  };
}

export function getLiveOrders(params) {
  return {
    type: constants.LOAD_LIVE_ORDERS,
    params,
  };
}

export function getLiveNewOrders(params) {
  return {
    type: constants.LOAD_LIVE_NEW_ORDERS,
    params,
  };
}

export function getLiveOrder(params) {
  return {
    type: constants.LOAD_LIVE_ORDER,
    params,
  };
}

export function updateOrderStatus(data) {
  return {
    type: constants.START_ORDER_STATUS,
    data,
  };
}

export function updatePaymentInfo(data) {
  return {
    type: constants.UPDATE_PAYMENT_INFO,
    data,
  };
}

export function loadBusinesses(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: constants.LOAD_BIZ_ONI,
    params,
  };
}

export function changeOniBiz(selectedBusiness) {
  return {
    type: constants.CHANGE_ONI_BIZ,
    selectedBusiness,
  };
}

export function changeSelectedOrderHistoryMode(selectedOrderHistoryMode) {
  // console.log('CHANGE_SELECTED_ORDER_HISTORY_MODE', selectedOrderHistoryMode);
  return {
    type: constants.CHANGE_SELECTED_ORDER_HISTORY_MODE,
    selectedOrderHistoryMode,
  };
}

export function loadOrderSourceGroupList(params) {
  return {
    type: constants.LOAD_ORDER_SOURCE,
    params,
  };
}

export function loadPaymentChannelsAll(params) {
  return {
    type: constants.LOAD_PAYMENT_CHANNELS_All,
    params: { ...params },
  };
}

export function loadPaymentStatusList(params) {
  return {
    type: constants.LOAD_PAYMENT_STATUS_LIST,
    params: { ...params },
  };
}

export function findRider(params) {
  return {
    type: constants.FIND_RIDER,
    params: { ...params },
  };
}

export function getCancellationReasons(params) {
  return {
    type: constants.GET_CANCELLATION_REASONS,
    params: { ...params },
  };
}

export function editCancellationReasonDetails(cancellationReasonDetails) {
  return {
    type: constants.EDIT_CANCELLATION_REASON_DETAILS,
    cancellationReasonDetails,
  };
}

export function loadPaymentChannelsByCountry(params) {
  return {
    type: constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY,
    params: { ...params, ...defaultParams },
  };
}

// Integration Status actions

export function changeBrandForIntStatus(selectedBrandForIntStatus) {
  return {
    type: constants.CHANGE_BRAND_FOR_INTEGRATION_STATUS,
    selectedBrandForIntStatus,
  };
}

export function changeBranchForIntStatus(selectedBranchForIntStatus) {
  return {
    type: constants.CHANGE_BRANCH_FOR_INTEGRATION_STATUS,
    selectedBranchForIntStatus,
  };
}

export function loadBrandsForIntStatus(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: constants.LOAD_BRANDS_FOR_INTEGRATION_STATUS,
    params,
  };
}

export function loadBranchesForIntStatus(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: constants.LOAD_BRANCHES_FOR_INTEGRATION_STATUS,
    params,
  };
}

export function loadProvidersForIntStatus(params) {
  return {
    type: constants.LOAD_PROVIDERS_FOR_INTEGRATION_STATUS,
    params,
  };
}

export function loadIntegrationStatus(brandId) {
  return {
    type: constants.LOAD_INTEGRATION_STATUS,
    brandId,
  };
}

export function cancelRiderAllocation(data) {
  return {
    type: constants.CANCEL_RIDER_ALLOCATION,
    data,
  };
}

export function getOrderAttachments(params) {
  return {
    type: constants.GET_ORDER_ATTACHMENTS,
    params,
  };
}

export function deleteOrder(params) {
  return {
    type: constants.DELETE_ORDER,
    params,
  };
}

export function updatePreparationTime(data) {
  return {
    type: constants.UPDATE_PREP_TIME,
    data,
  };
}

export function getList(params) {
  return {
    type: constants.GET_LIST,
    params,
  };
}

export function loadDetails(meta) {
  return {
    type: constants.LOAD_DETAILS,
    meta,
  };
}

export function editField(params) {
  return {
    type: constants.EDIT_FIELD,
    params,
  };
}

export function saveEntity(meta) {
  return {
    type: constants.SAVE_ENTITY,
    meta,
  };
}
