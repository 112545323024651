import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { readablePrice } from '../../../utils/getCurrency';
import {
  getIntervalAndMax,
  getNonZeroAxisSeries,
} from '../../../utils/nativeAnalyticsUtils';
import {
  ORDER_COUNT_KEYS,
  BUSY_MODE_KEYS,
} from '../../../config/constants/analytics';

const BarChart = ({
  id,
  xAxisData,
  yAxisData,
  seriesName,
  currencyObj,
  exportedCsvHeaderTitle,
}) => {
  const isOrderCountChart = Object.values(ORDER_COUNT_KEYS).includes(id);
  const isBusyModeChart = Object.values(BUSY_MODE_KEYS).includes(id);
  const maxValueOfSeries = Math.max(...yAxisData);
  const { yAxisMaxValue, yAxisTickAmount } =
    getIntervalAndMax(maxValueOfSeries) || {};

  const { xAxisSeries, yAxisSeries } = getNonZeroAxisSeries(
    xAxisData,
    yAxisData,
  );

  const chartConfig = {
    options: {
      tooltip: {
        y: {
          formatter(value) {
            return isOrderCountChart || isBusyModeChart
              ? value
              : readablePrice(value * 100, currencyObj);
          },
        },
      },
      chart: {
        id,
        type: 'bar',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: id,
              headerCategory: exportedCsvHeaderTitle,
              headerValue: 'value',
            },
            svg: {
              filename: id,
            },
            png: {
              filename: id,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'category',
        categories: xAxisSeries,
        labels: {
          hideOverlappingLabels: false,
          trim: true,
        },
      },
      yaxis: {
        max: yAxisMaxValue || 6,
        tickAmount: yAxisTickAmount || 1,
        labels: {
          formatter(val) {
            const floatPointCount = isOrderCountChart ? 0 : 2;
            return val.toFixed(floatPointCount);
          },
        },
      },
      colors: ['#6A13F4'],
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
    },
    series: [
      {
        name: seriesName,
        data: yAxisSeries,
      },
    ],
  };

  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="bar"
      height="350"
    />
  );
};

BarChart.propTypes = {
  id: PropTypes.string,
  xAxisData: PropTypes.array,
  yAxisData: PropTypes.array,
  seriesName: PropTypes.string,
  currencyObj: PropTypes.object,
  exportedCsvHeaderTitle: PropTypes.string,
};

export default BarChart;
