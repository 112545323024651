import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectProperty } from '../selectors';
import PerformanceTable from './PerformanceTable';

const BrandPerformance = ({ brandPerformanceData }) => (
  <PerformanceTable title="Brand" performanceData={brandPerformanceData} />
);

BrandPerformance.propTypes = {
  brandPerformanceData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  brandPerformanceData: makeSelectProperty('brandPerformanceData'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BrandPerformance);
