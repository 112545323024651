import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SCSwitch,
  SCButton,
  SCDatepicker,
  SCSelect,
  SCSelectV2,
} from 'rollup-allspark';
import { differenceInDays } from 'date-fns';
import { isArray } from '../../utils/isArray';
import HelpPopover from '../../components/HelpPopover';
import * as actions from './actions';
import reducer from './reducer';
import saga from './saga';
import * as selectors from './selectors';
import { subtractDays } from '../../utils/format-date';
import {
  DATE_FILTER_DAY_LIMIT,
  TIME_PERIOD_LIST,
  DEFAULT_TIME_PERIOD,
  OWN_CHANNEL_KEYS,
  COMPARISON_TYPE,
} from '../../config/constants/analytics';
import {
  DEFAULT_ALL_PAGE_SIZE,
  DEFAULT_PAGE_NO,
} from '../../config/constants/pagination';
import {
  AGGREGATOR_COMPARISON_HELP,
  DATE_RANGE_HELP,
  TIME_PERIOD_HELP,
  BRANCH_COMPARISON_HELP,
  BRAND_COMPARISON_HELP,
  TIME_RANGE_HELP,
} from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { PROVIDERS } from '../../config/env';

import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import AnalyticsHourPicker from './AnalyticsHourPicker';

const AnalyticsFilters = ({
  getBusinesses,
  businesses,
  selectedBusiness,
  handleBusinessChange,
  getBranches,
  branches,
  selectedBranch,
  handleBranchChange,
  getBrands,
  brands,
  selectedBrand,
  handleBrandChange,
  getProviders,
  selectedAnalyticsProvider,
  analyticsProviders,
  analyticsStartDate,
  analyticsEndDate,
  handleStartDateChange,
  handleEndDateChange,
  selectedTimePeriod,
  handleTimePeriodChange,
  handleAnalyticProviderChange,
  handleComparisonToggle,
  handleAnalyticsFilterApply,
  handleAnalyticsFilterReset,
  handleTimePeriodChangeStatus,
  selectedChannel,
  handleOwnChannelChange,
  handleMoreThan30DaysStatusChange,
  handleComparisonTypeChange,
  selectedComparisonType,
  handleChangeEntity,
}) => {
  const dateToday = new Date();
  const dateEarlierSevenDays = subtractDays(dateToday, 7);
  // ALL HOOKS
  // ========

  // INJECT REDUCERS
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });

  const [isFilterValuesChanged, setIsFilterValuesChanged] = useState(false);
  const [canApplyFilter, setCanApplyFilter] = useState(false);
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false);
  const [isTimeRangePopoverOpen, setIsTimeRangePopoverOpen] = useState(false);
  const [isTimePeriodPopoverOpen, setIsTimePeriodPopoverOpen] = useState(false);
  const [isProviderComparisonPopoverOpen, setIsProviderComparisonPopoverOpen] =
    useState(false);
  const [isBrandComparisonPopoverOpen, setIsBrandComparisonPopoverOpen] =
    useState(false);
  const [isBranchComparisonPopoverOpen, setIsBranchComparisonPopoverOpen] =
    useState(false);
  const [isKlikitPresent, setIsKlikitPresent] = useState(false);
  const [allComparisonSwitchesOff, setAllComparisonSwitchesOff] =
    useState(false);

  const labelEL = item => `${item.id}. ${item.title}`;

  // USE EFFECTS
  useEffect(() => {
    handleResetFilters();
  }, []);

  useEffect(() => {
    const params = {
      page: DEFAULT_PAGE_NO,
      size: DEFAULT_ALL_PAGE_SIZE,
    };

    if (selectedBusiness && selectedBusiness.id) {
      params.filterByBusiness = selectedBusiness.id;
      params.size = 1000;
      getBrands(params);
      getBranches(params);
    }
  }, [selectedBusiness]);

  useEffect(() => {
    if (branches && branches.length > 0) {
      const countryIds = [...new Set(branches.map(b => b.country_id))];
      getProviders({ filterByCountry: countryIds.join(',') });
    }
  }, [branches]);

  useEffect(() => {
    const params = {
      page: DEFAULT_PAGE_NO,
      size: DEFAULT_ALL_PAGE_SIZE,
    };

    if (isArray(selectedBranch)) {
      params.filterByBranch = selectedBranch.map(b => b.id).join(',');
      params.size = 1000;
      getBrands(params);
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedBusiness && selectedBusiness.id) {
      handleAnalyticProviderChange([...analyticsProviders]);
    }
  }, [analyticsProviders]);

  useEffect(() => {
    const hasBusinessId = selectedBusiness && selectedBusiness.id;
    const hasBranchIds = selectedBranch && selectedBranch.length;
    const hasBrandIds = selectedBrand && selectedBrand.length;
    const canApply = hasBusinessId && (hasBrandIds || hasBranchIds);
    setCanApplyFilter(canApply);
  }, [selectedBusiness, selectedBranch, selectedBrand]);

  useEffect(() => {
    const hasKlikit = selectedAnalyticsProvider.some(
      provider => provider.id === PROVIDERS.KLIKIT,
    );
    handleOwnChannelChange(hasKlikit ? OWN_CHANNEL_KEYS : []);
    setIsKlikitPresent(hasKlikit);
  }, [selectedAnalyticsProvider]);

  // HANDLERS
  // =======
  const handleDateChange = (startDate, endDate) => {
    if (!startDate || !endDate) return;

    handleStartDateChange(startDate);
    handleEndDateChange(endDate);
  };

  const getDefaultFilterData = () => {
    const params = {
      page: DEFAULT_PAGE_NO,
      size: DEFAULT_ALL_PAGE_SIZE,
    };

    getBusinesses(params);
    handleTimePeriodChange(DEFAULT_TIME_PERIOD);
    handleDateChange(dateEarlierSevenDays, dateToday);
  };

  const handleSelectDateRange = data => {
    setIsFilterValuesChanged(true);
    let startDate = null;
    let endDate = null;
    if (
      data &&
      data.data &&
      data.data.length === 2 &&
      data.data[0] &&
      data.data[1]
    ) {
      [startDate, endDate] = data.data;
      handleDateChange(startDate, endDate);
    }
  };

  const handleChangeProviderComparison = (value, switchName) => {
    if (value) {
      const isAggregatorSwitch = switchName === COMPARISON_TYPE.AGGREGATOR;
      handleComparisonTypeChange(switchName);
      setAllComparisonSwitchesOff(false);
      handleComparisonToggle(isAggregatorSwitch);
    } else {
      handleComparisonTypeChange(null);
      handleComparisonToggle(false);
    }
    setIsFilterValuesChanged(true);
  };

  const handleResetFilters = () => {
    handleAnalyticsFilterReset();
    getDefaultFilterData();
    handleBusinessChange(null);
    handleAnalyticProviderChange([]);
    handleBranchChange([]);
    handleBrandChange([]);
    handleDateChange(dateEarlierSevenDays, dateToday);
    handleTimePeriodChange(DEFAULT_TIME_PERIOD);
    handleComparisonToggle(true);
    setIsFilterValuesChanged(false);
    handleChangeEntity({
      key: 'analyticsStartTime',
      value: '',
    });
    handleChangeEntity({
      key: 'analyticsEndTime',
      value: '',
    });
    handleChangeEntity({
      key: 'analyticsHourReset',
      value: true,
    });
  };
  const onChangeBusiness = selectedBusinessInDropdown => {
    handleBusinessChange(selectedBusinessInDropdown);
    if (!selectedBusinessInDropdown) {
      handleAnalyticProviderChange([]);
      handleBranchChange([]);
      handleBrandChange([]);
      return;
    }
    setIsFilterValuesChanged(true);
  };

  const checkDateRange = () => {
    const startDate = new Date(analyticsStartDate);
    const endDate = new Date(analyticsEndDate);

    // Calculate the difference in months
    const dayDiff = differenceInDays(endDate, startDate);

    // Return true if dayDiff is more than 30, otherwise return false
    return dayDiff > 30;
  };

  const handleApplyFilterAction = e => {
    const isMoreThan30Days = checkDateRange();
    handleTimePeriodChangeStatus(false);
    handleAnalyticsFilterApply(e, {
      isMoreThan30Days,
    });
    handleMoreThan30DaysStatusChange(isMoreThan30Days);
  };

  // BRANCH LIST GENERATOR
  // ====================
  const onChangeBranch = selectedBranchInDropdown => {
    setIsFilterValuesChanged(true);
    const selectedBranches =
      selectedBranchInDropdown && selectedBranchInDropdown.length
        ? selectedBranchInDropdown
        : branches;
    const countryIds =
      selectedBranches && selectedBranches.length
        ? [...new Set(selectedBranches.map(eb => eb.country_id))]
        : [];
    const params =
      countryIds && countryIds.length
        ? { filterByCountry: countryIds.join(',') }
        : {};
    getProviders(params);
    handleBranchChange([...selectedBranchInDropdown]);
  };

  // BRAND LIST GENERATOR
  // ====================
  const handleSelectedBrands = selectedBrandsInDropdown => {
    setIsFilterValuesChanged(true);
    handleBrandChange([...selectedBrandsInDropdown]);
  };

  // PROVIDER LIST GENERATOR
  // ======================
  const handleSelectedProvider = selectedProvidersInDropdown => {
    setIsFilterValuesChanged(true);
    handleAnalyticProviderChange([...selectedProvidersInDropdown]);
  };

  // TIME PERIOD
  // ===========
  const handleSelectedTimePeriod = selectedTimePeriodInDropdown => {
    let startDate = subtractDays(dateToday, 7);

    if (
      selectedTimePeriodInDropdown &&
      selectedTimePeriodInDropdown.slug === 'weekly'
    ) {
      startDate = subtractDays(dateToday, 30);
    } else if (
      selectedTimePeriodInDropdown &&
      selectedTimePeriodInDropdown.slug === 'monthly'
    ) {
      startDate = subtractDays(dateToday, 90);
    }

    handleDateChange(startDate, dateToday);
    setIsFilterValuesChanged(true);
    handleTimePeriodChange(selectedTimePeriodInDropdown);
  };

  const analyticsFilterTimePeriodList = (
    <div className="flex flex-col w-full lg:w-1/4 mb-2 lg:mb-0">
      <div className="text-xs text-grey-darker flex mb-1">
        <div className="mr-2">Time Period</div>
        <HelpPopover
          isPopoverOpen={isTimePeriodPopoverOpen}
          position="right"
          isHtmlElement
          content={TIME_PERIOD_HELP}
          setIsPopoverOpen={setIsTimePeriodPopoverOpen}
          iconClassNames="sc-information cursor-pointer"
        />
      </div>
      <SCSelect
        allowEmpty={false}
        options={TIME_PERIOD_LIST}
        value={selectedTimePeriod}
        label="title"
        trackBy="slug"
        placeholder="Select time period"
        onSelect={handleSelectedTimePeriod}
        disableSelectedItem
      />
    </div>
  );

  // OWN CHANNEL
  // ============
  const handleSelectChannel = selectedChannelInDropdown => {
    setIsFilterValuesChanged(true);
    handleOwnChannelChange([...selectedChannelInDropdown]);
  };

  const onwChannelElement = isKlikitPresent ? (
    <div className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0 lg:mr-4">
      <SCSelect
        multiple
        options={OWN_CHANNEL_KEYS}
        trackBy="id"
        label="title"
        title="Klikit Channel"
        placeholder="Select channel"
        value={selectedChannel}
        onSelect={handleSelectChannel}
      />
    </div>
  ) : null;

  return (
    <div className="w-full flex flex-col">
      {/* Filter fields row */}
      <div className="w-full flex flex-col">
        {/* Filter row 1 */}
        <div className="flex w-full mb-4 flex-col lg:flex-row">
          <SCSelect
            searchable
            options={businesses}
            trackBy="id"
            customLabel={item => labelEL(item)}
            searchAbleAttribute={['title', 'id']}
            title="Business"
            placeholder="Select business"
            value={selectedBusiness}
            onSelect={onChangeBusiness}
            className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0  lg:mr-4"
          />

          <SCSelectV2
            searchable
            multiple
            options={branches}
            trackBy="id"
            customLabel={item => labelEL(item)}
            searchAbleAttribute={['title', 'id']}
            title="Branch"
            placeholder="Select branch"
            value={selectedBranch}
            onSelect={onChangeBranch}
            className="w-full mb-2 mr-0 lg:w-1/4  lg:mb-0  lg:mr-4"
          />

          <SCSelectV2
            searchable
            options={brands}
            multiple
            trackBy="id"
            customLabel={item => labelEL(item)}
            searchAbleAttribute={['title', 'id']}
            title="Brand"
            placeholder="Select brand"
            value={selectedBrand}
            onSelect={handleSelectedBrands}
            className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0 lg:mr-4"
          />

          <SCSelectV2
            searchable
            multiple
            options={analyticsProviders}
            trackBy="id"
            label="title"
            title="Aggregators"
            placeholder="Select Aggregator"
            value={selectedAnalyticsProvider}
            onSelect={handleSelectedProvider}
            className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0"
          />
        </div>
        {/* Filter row 2 */}
        <div className="flex w-full mb-4 flex-col lg:flex-row ">
          {/* OWN CHANNEL */}
          {onwChannelElement}
          {/* Date Range */}
          <div className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0 lg:mr-4">
            <div className="text-xs text-grey-darker flex flex-1">
              <div className="mr-2">Date Range</div>
              <HelpPopover
                isPopoverOpen={isDateRangePopoverOpen}
                position="right"
                isHtmlElement
                content={DATE_RANGE_HELP}
                setIsPopoverOpen={setIsDateRangePopoverOpen}
                iconClassNames="sc-information cursor-pointer"
              />
            </div>
            <div className="flex flex-col -mt-3">
              <SCDatepicker
                helpText="Select Range"
                elementType="input"
                placeholder="Select date range"
                isClearable={false}
                selectsRange
                startDate={analyticsStartDate}
                endDate={analyticsEndDate}
                dateFormat="yyyy-MM-dd"
                displayFormat={"MMM d ''yy"}
                maxDaysLimit={DATE_FILTER_DAY_LIMIT}
                minDate={subtractDays(new Date(), DATE_FILTER_DAY_LIMIT - 1)}
                maxDate={new Date()}
                disableLabel
                onSelect={handleSelectDateRange}
              />
            </div>
          </div>
          <div className="w-full mb-2 mr-0 lg:w-1/4 lg:mb-0 lg:mr-4">
            <div className="text-xs text-grey-darker flex flex-1">
              <div className="mr-2">Time Range</div>
              <HelpPopover
                isPopoverOpen={isTimeRangePopoverOpen}
                position="right"
                isHtmlElement
                content={TIME_RANGE_HELP}
                setIsPopoverOpen={setIsTimeRangePopoverOpen}
                iconClassNames="sc-information cursor-pointer"
              />
            </div>
            <div>
              <AnalyticsHourPicker containerClass="mt-1" />
            </div>
          </div>
          {/* Time Period */}
          {analyticsFilterTimePeriodList}
        </div>
      </div>
      {/* Filter action row */}
      <div className="w-full justify-between flex flex-col lg:flex-row">
        <div className="flex gap-x-4 mb-4 lg:mb-0 flex-wrap">
          {/* Comparison */}
          <div className="flex font-bold text-black py-1 pr-4 border-r-2 border-r-grey-darker mb-2">
            Compare by
          </div>
          {/* AGGREGATOR */}
          <div className="flex items-center w-auto mb-2 pr-4 border-r border-grey">
            <div className="flex gap-x-1 mr-2">
              Aggregator
              <HelpPopover
                isPopoverOpen={isProviderComparisonPopoverOpen}
                position="left"
                isHtmlElement
                content={AGGREGATOR_COMPARISON_HELP}
                setIsPopoverOpen={setIsProviderComparisonPopoverOpen}
                iconClassNames="sc-information cursor-pointer"
              />
            </div>
            <div className="flex justify-center">
              <SCSwitch
                value={
                  selectedComparisonType === COMPARISON_TYPE.AGGREGATOR ||
                  allComparisonSwitchesOff
                }
                handleChange={value =>
                  handleChangeProviderComparison(
                    value,
                    COMPARISON_TYPE.AGGREGATOR,
                  )
                }
              />
            </div>
          </div>
          {/* BRAND */}
          <div className="flex items-center w-auto mb-2 pr-4 border-r border-grey">
            <div className="flex gap-x-1 mr-2">
              Brand
              <HelpPopover
                isPopoverOpen={isBrandComparisonPopoverOpen}
                position="left"
                isHtmlElement
                content={BRAND_COMPARISON_HELP}
                setIsPopoverOpen={setIsBrandComparisonPopoverOpen}
                iconClassNames="sc-information cursor-pointer"
              />
            </div>
            <div className="flex justify-center">
              <SCSwitch
                value={
                  selectedComparisonType === COMPARISON_TYPE.BRAND ||
                  allComparisonSwitchesOff
                }
                handleChange={value =>
                  handleChangeProviderComparison(value, COMPARISON_TYPE.BRAND)
                }
              />
            </div>
          </div>
          {/* BRANCH */}
          <div className="flex items-center w-auto mb-2">
            <div className="flex gap-x-1 mr-2">
              Branch
              <HelpPopover
                isPopoverOpen={isBranchComparisonPopoverOpen}
                position="left"
                isHtmlElement
                content={BRANCH_COMPARISON_HELP}
                setIsPopoverOpen={setIsBranchComparisonPopoverOpen}
                iconClassNames="sc-information cursor-pointer"
              />
            </div>
            <div className="flex justify-center">
              <SCSwitch
                value={
                  selectedComparisonType === COMPARISON_TYPE.BRANCH ||
                  allComparisonSwitchesOff
                }
                handleChange={value =>
                  handleChangeProviderComparison(value, COMPARISON_TYPE.BRANCH)
                }
              />
            </div>
          </div>
        </div>
        <div className="flex items-end justify-end">
          <SCButton
            variant="primary-outline"
            className="mr-2"
            size="sm"
            action={handleResetFilters}
            disabled={!isFilterValuesChanged}
            label="Reset"
          />
          <SCButton
            size="sm"
            action={handleApplyFilterAction}
            disabled={!canApplyFilter}
            label="Apply Filter"
          />
        </div>
      </div>
    </div>
  );
};

AnalyticsFilters.propTypes = {
  getBusinesses: PropTypes.func,
  businesses: PropTypes.array,
  selectedBusiness: PropTypes.any,
  handleBusinessChange: PropTypes.func,
  getBranches: PropTypes.func,
  branches: PropTypes.array,
  selectedBranch: PropTypes.any,
  handleBranchChange: PropTypes.func,
  getBrands: PropTypes.func,
  brands: PropTypes.array,
  selectedBrand: PropTypes.any,
  handleBrandChange: PropTypes.func,
  getProviders: PropTypes.func,
  analyticsProviders: PropTypes.array,
  selectedAnalyticsProvider: PropTypes.any,
  handleAnalyticProviderChange: PropTypes.func,
  analyticsStartDate: PropTypes.any,
  analyticsEndDate: PropTypes.any,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
  selectedTimePeriod: PropTypes.object,
  handleTimePeriodChange: PropTypes.func,
  handleComparisonToggle: PropTypes.func,
  handleAnalyticsFilterApply: PropTypes.func,
  handleAnalyticsFilterReset: PropTypes.func,
  handleTimePeriodChangeStatus: PropTypes.func,
  selectedChannel: PropTypes.any,
  handleOwnChannelChange: PropTypes.func,
  handleMoreThan30DaysStatusChange: PropTypes.func,
  handleComparisonTypeChange: PropTypes.func,
  selectedComparisonType: PropTypes.string,
  handleChangeEntity: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  businesses: selectors.makeSelectAnalyticsBusinesses(),
  selectedBusiness: selectors.makeSelectAnalyticsSelectedBusiness(),
  branches: selectors.makeSelectAnalyticsBranches(),
  selectedBranch: selectors.makeSelectAnalyticsSelectedBranch(),
  brands: selectors.makeSelectAnalyticsBrands(),
  selectedBrand: selectors.makeSelectAnalyticsSelectedBrand(),
  brandLoading: selectors.makeSelectAnalyticsBrandLoading(),
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  selectedAnalyticsProvider: selectors.makeSelectAnalyticsSelectedProvider(),
  analyticsStartDate: selectors.makeSelectAnalyticsStartDate(),
  analyticsEndDate: selectors.makeSelectAnalyticsEndDate(),
  selectedTimePeriod: selectors.makeSelectSelectedTimePeriod(),
  selectedChannel: selectors.makeSelectSelectedChannel(),
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
});

function mapDispatchToProps(dispatch) {
  return {
    getBusinesses: params => dispatch(actions.loadBusinesses(params)),
    getBranches: params => dispatch(actions.loadBranches(params)),
    getBrands: params => dispatch(actions.loadBrands(params)),
    getProviders: params => dispatch(actions.loadAnalyticsProvider(params)),
    handleBusinessChange: val => dispatch(actions.changeBusinessSelection(val)),
    handleBranchChange: val => dispatch(actions.changeBranchSelection(val)),
    handleBrandChange: val => dispatch(actions.changeBrandSelection(val)),
    handleAnalyticProviderChange: val =>
      dispatch(actions.changeAnalyticsProviderSelection(val)),
    handleStartDateChange: val =>
      dispatch(actions.changeAnalyticsStartDate(val)),
    handleEndDateChange: val => dispatch(actions.changeAnalyticsEndDate(val)),
    handleTimePeriodChange: val => dispatch(actions.changeTimePeriod(val)),
    handleComparisonToggle: val =>
      dispatch(actions.changeComparisonToggle(val)),
    handleTimePeriodChangeStatus: val =>
      dispatch(actions.changeTimePeriodFilterStatus(val)),
    handleOwnChannelChange: val => dispatch(actions.changeOwnChannel(val)),
    handleMoreThan30DaysStatusChange: val =>
      dispatch(actions.changeMoreThan30DaysStatus(val)),
    handleComparisonTypeChange: val =>
      dispatch(actions.changeComparisonType(val)),
    handleChangeEntity: params => dispatch(actions.changeEntity(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AnalyticsFilters);
