import * as constants from './constants';

export function loadSummary(params) {
  return {
    type: constants.LOAD_SUMMARY,
    params,
  };
}

export function loadAnalyticsData(params) {
  return {
    type: constants.LOAD_ANALYTICS_DATA,
    params,
  };
}

export function loadOrdersData(params) {
  return {
    type: constants.LOAD_ORDERS_DATA,
    params,
  };
}

export function loadBusinesses(params) {
  return {
    type: constants.LOAD_ANALYTICS_BUSINESS,
    params,
  };
}

export function changeBusinessSelection(selectedBusiness) {
  return {
    type: constants.CHANGE_ANALYTICS_BUSINESS,
    selectedBusiness,
  };
}

export function loadBranches(params) {
  return {
    type: constants.LOAD_ANALYTICS_BRANCHES,
    params,
  };
}

export function changeBranchSelection(selectedBranch) {
  return {
    type: constants.CHANGE_ANALYTICS_BRANCH,
    selectedBranch,
  };
}

export function loadBrands(params) {
  return {
    type: constants.LOAD_ANALYTICS_BRANDS,
    params,
  };
}

export function changeBrandSelection(selectedBrand) {
  return {
    type: constants.CHANGE_ANALYTICS_BRAND,
    selectedBrand,
  };
}

export function loadAnalyticsProvider(params) {
  return {
    type: constants.LOAD_ANALYTICS_PROVIDERS,
    params,
  };
}

export function changeAnalyticsProviderSelection(selectedAnalyticsProvider) {
  return {
    type: constants.CHANGE_ANALYTICS_PROVIDER,
    selectedAnalyticsProvider,
  };
}

export function changeAnalyticsStartDate(analyticsStartDate) {
  return {
    type: constants.CHANGE_ANALYTICS_START_DATE,
    analyticsStartDate,
  };
}

export function changeAnalyticsEndDate(analyticsEndDate) {
  return {
    type: constants.CHANGE_ANALYTICS_END_DATE,
    analyticsEndDate,
  };
}

export function changeTimePeriod(selectedTimePeriod) {
  return {
    type: constants.CHANGE_ANALYTICS_TIME_PERIOD,
    selectedTimePeriod,
  };
}

export function changeTimePeriodFilterStatus(isTimePeriodFilterStatusChanged) {
  return {
    type: constants.CHANGE_TIME_PERIOD_FILTER_STATUS,
    isTimePeriodFilterStatusChanged,
  };
}

export function changeComparisonToggle(comparisonEnabled) {
  return {
    type: constants.CHANGE_COMPARISON_TOGGLE,
    comparisonEnabled,
  };
}

export function updatePagination(pageNo, pageSize) {
  return {
    type: constants.UPDATE_PAGINATION,
    pageNo,
    pageSize,
  };
}

export function loadAnalyticsCsv(params) {
  return {
    type: constants.LOAD_ANALYTICS_CSV,
    params,
  };
}

// STATUS
export function loadStatus() {
  return {
    type: constants.LOAD_STATUS,
  };
}

export function changeIsFirstTimeLoaded(isFirstTimeLoaded) {
  return {
    type: constants.CHANGE_IS_LOADED_FIRST_TIME,
    isFirstTimeLoaded,
  };
}

export function resetAnalyticsData() {
  return {
    type: constants.RESET_ANALYTICS_DATA,
  };
}
// ITEM BREAK DOWN
export function loadItemBreakdownData(params) {
  return {
    type: constants.LOAD_ITEM_BREAKDOWN_DATA,
    params,
  };
}

// OWN CHANNEL
export function changeOwnChannel(selectedChannel) {
  return {
    type: constants.CHANGE_OWN_CHANNEL,
    selectedChannel,
  };
}

// BUSY MODE
export function loadBusyModeAnalyticsData(params) {
  return {
    type: constants.LOAD_BUSY_MODE_ANALYTICS_DATA,
    params,
  };
}

export function loadBusyModeLogData(params) {
  return {
    type: constants.LOAD_BUSY_MODE_LOG_DATA,
    params,
  };
}

export function loadPaymentMethodData(params) {
  return {
    type: constants.LOAD_PAYMENT_METHOD_DATA,
    params,
  };
}

export function changeMoreThan30DaysStatus(isMoreThan30Days) {
  return {
    type: constants.CHANGE_MORE_THAN_30_DAYS_STATUS,
    isMoreThan30Days,
  };
}
export function changeComparisonType(selectedComparisonType) {
  return {
    type: constants.CHANGE_COMPARISON_TYPE,
    selectedComparisonType,
  };
}

export function getList(params) {
  return {
    type: constants.GET_LIST,
    params,
  };
}
export function changeEntity(payload) {
  return {
    type: constants.CHANGE_ENTITY,
    payload,
  };
}

export function editField(params) {
  return {
    type: constants.EDIT_FIELD,
    params,
  };
}

export function loadData(meta) {
  return {
    type: constants.GET_DATA,
    meta,
  };
}
