import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

const CustomComponent = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    onMouseEnter={() => props.setIsOpen(true)}
    onMouseLeave={() => props.setIsOpen(false)}
    className="flex justify-between justify-items-center"
    role="presentation"
  >
    {props.children}
  </div>
));

const KTTooltip = ({
  isHtmlElement,
  children,
  content,
  positions,
  iconSize,
  iconClassNames,
  align,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      positions={positions}
      reposition
      align={align}
      transitionDuration={0}
      containerStyle={{
        zIndex: 99999,
        cursor: 'auto',
        border: '1px solid #eaf0f6',
        backgroundColor: 'white',
        boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',
        borderRadius: '4px',
        maxWidth: '300px',
        padding: '16px 16px',
      }}
      onClickOutside={() => {
        setIsOpen(false);
      }}
      content={
        <div>
          {isHtmlElement ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <div>{content}</div>
          )}
        </div>
      }
    >
      <CustomComponent setIsOpen={setIsOpen} isOpen={isOpen}>
        {children || <i className={`${iconSize} ${iconClassNames}`} />}
      </CustomComponent>
    </Popover>
  );
};

KTTooltip.defaultProps = {
  isHtmlElement: false,
  positions: ['right', 'top', 'bottom', 'left'],
  align: 'right',
  iconClassNames: 'text-blue sc-question cursor-pointer',
  iconSize: 'text-base',
};

KTTooltip.propTypes = {
  isHtmlElement: PropTypes.bool,
  content: PropTypes.any,
  positions: PropTypes.arrayOf(
    PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  ),
  align: PropTypes.string,
  iconClassNames: PropTypes.string,
  iconSize: PropTypes.string,
};

export default KTTooltip;
