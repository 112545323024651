import { KLIKIT_CDN_BASE_URL } from '../env';

export const NEW_NOTIFICATION_SOUND = `${KLIKIT_CDN_BASE_URL}/audio/new.wav`;
export const CANCELLED_ORDER_NOTIFICATION_SOUND = `${KLIKIT_CDN_BASE_URL}/audio/cancel.wav`;
export const ORDER_STATUS = {
  PLACED: 1,
  ACCEPTED: 2,
  CANCELLED: 3,
  READY: 4,
  DELIVERED: 5,
  SCHEDULED: 6,
  DRIVER_ASSIGNED: 7,
  DRIVER_ARRIVED: 8,
  PICKEDUP: 9,
  VOIDED: 11,
};

export const ORDER_STATUS_LIST = [
  { id: 1, status: 'Placed' },
  { id: 2, status: 'Accepted' },
  { id: 3, status: 'Cancelled' },
  { id: 4, status: 'Ready' },
  { id: 5, status: 'Delivered' },
  { id: 6, status: 'Scheduled' },
  { id: 7, status: 'Driver Assigned' },
  { id: 8, status: 'Driver Arrived' },
  { id: 9, status: 'Picked Up' },
];

export const ORDER_TYPE = {
  DELIVER: 2,
  PICKUP: 1,
  DINE_IN: 3,
};
export const ORDER_TYPE_LIST = [
  { id: 1, label: 'Pickup' },
  { id: 2, label: 'Delivery' },
  {
    id: 3,
    label: 'Dine In',
  },
];
export const ORDER_SOURCE = [
  {
    id: 1,
    title: 'Facebook',
  },
  {
    id: 2,
    title: 'Messenger',
  },
  {
    id: 3,
    title: 'Instagram',
  },
  {
    id: 4,
    title: 'WhatsApp',
  },
  {
    id: 5,
    title: 'Viber',
  },
  {
    id: 6,
    title: 'Phone',
  },
  {
    id: 7,
    title: 'Other',
  },
];
export const PAID_PAYMENT_STATUS_ID = 1;
export const PENDING_PAYMENT_STATUS_ID = 3;
export const FAILED_PAYMENT_STATUS_ID = 2;
export const DEFAULT_PAYMENT_STATUS_ID_FOR_MANUAL_ORDER =
  PENDING_PAYMENT_STATUS_ID;
export const DEFAULT_PAYMENT_STATUS_LABEL_FOR_MANUAL_ORDER = 'Pending';
export const DISCOUNT_TYPE = [
  {
    id: 1,
    title: 'Flat',
    value: 1,
  },
  {
    id: 2,
    title: 'Percent (%)',
    value: 2,
  },
];
export const ONI_ORDER_HISTORY_MODES = MULTI_LANG_TEXTS => [
  {
    title:
      (MULTI_LANG_TEXTS &&
        MULTI_LANG_TEXTS.LIST_FILTER_ORDER_HISTORY_TYPE_ACTIVE_ORDERS_PLACEHOLDER) ||
      'Active Orders',
    id: 'active_orders',
  },
  {
    title:
      (MULTI_LANG_TEXTS &&
        MULTI_LANG_TEXTS.LIST_FILTER_ORDER_HISTORY_TYPE_PAST_ORDERS_PLACEHOLDER) ||
      'Order History',
    id: 'past_orders',
  },
];
export const ONI_ACTIVE_ORDER_HISTORY_MODE_ID = 'active_orders';
export const ONI_PAST_ORDER_HISTORY_MODE_ID = 'past_orders';
export const ONI_DEFAULT_ORDER_HISTORY_MODE_ID =
  ONI_ACTIVE_ORDER_HISTORY_MODE_ID;
export const OTHER_ORDER_SOURCE_ID = 10;
export const CUSTOMIZABLE_PRICE_TYPE = {
  DELIVERY_FEE: 'delivery_fee',
  DISCOUNT: 'discount_value',
  ADDITIONAL_FEE: 'additional_fee',
  IS_ROUNDING_OFF_APPLICABLE: 'is_rounding_off_applicable',
};

export const INDONESIAN_CURRENCY_CODE = 'IDR';
export const JAPAN_CURRENCY_CODE = 'JPY';
export const TAIWAN_CURRENCY_CODE = 'TWD';

export const PAYMENT_STATUS_PAID_ID = 1;
export const PAYMENT_STATUS_REFUNDED_ID = 4;
export const PAYMENT_STATUS_REFUND_REQUESTED_ID = 5;
export const PAYMENT_STATUS_REFUND_REQUESTED_FAILED_ID = 6;
export const PAYMENT_STATUS_REFUND_FAILED = 7;
export const ORDER_FULFILLMENT_STATUS = {
  DISPATCHING: { id: 1, status: 'DISPATCHING', label: 'FINDING RIDER' },
  ALLOCATING: { id: 2, status: 'ALLOCATING', label: 'ALLOCATING RIDER' },
  PENDING_PICKUP: { id: 3, status: 'PENDING_PICKUP', label: 'FOUND RIDER' },
  PICKING_UP: { id: 4, status: 'PICKING_UP', label: 'PICKING UP' },
  IN_DELIVERY: { id: 5, status: 'IN_DELIVERY', label: 'IN DELIVERY' },
  COMPLETED: { id: 6, status: 'COMPLETED', label: 'COMPLETED' },
  RETURNED: { id: 7, status: 'RETURNED', label: 'RETURNED' },
  FAILED: { id: 8, status: 'FAILED', label: 'FAILED' },
  CANCELLED: { id: 9, status: 'CANCELLED', label: 'CANCELLED' },
  IN_RETURN: { id: 10, status: 'IN_RETURN', label: 'IN RETURN' },
  DISPATCH_FAILED: {
    id: 11,
    status: 'DISPATCH_FAILED',
    label: 'RIDER REQUEST FAILED',
  },
};
export const ORDER_FULFILLMENT_STATUS_IDS_HAVING_RIDER = [
  ORDER_FULFILLMENT_STATUS.PENDING_PICKUP.id,
  ORDER_FULFILLMENT_STATUS.PICKING_UP.id,
  ORDER_FULFILLMENT_STATUS.IN_DELIVERY.id,
  ORDER_FULFILLMENT_STATUS.COMPLETED.id,
  ORDER_FULFILLMENT_STATUS.IN_RETURN.id,
];

export const ORDER_FULFILLMENT_STATUS_CANCELLED_ID =
  ORDER_FULFILLMENT_STATUS.CANCELLED.id;

export const ORDER_CANCELLATION_LIST_TYPE_IDS = {
  RESTAURANT: 1,
  WEBSHOP: 2,
};
export const CANCELLATION_REASON_OTHER_ID = 7;

export const ORDER_CANCELLED_BY_LIST = [
  {
    id: 1,
    label: 'Restaurant',
  },
  {
    id: 2,
    label: 'Customer',
  },
  {
    id: 3,
    label: 'Provider',
  },
  {
    id: 4,
    label: 'Fulfillment Partner',
  },
  {
    id: 5,
    label: 'SYSTEM',
  },
];

export const PROVIDER_STATUS_ACTIVE = 'Active';
export const PROVIDER_STATUS_INACTIVE = 'Inactive';
export const PROVIDER_STATUS_NOT_AVAILABLE = 'N/A';

export const DRIVE_THRU_TYPE = {
  id: 2,
  label: 'Drive Thru',
};

export const CREATE_QRIS_ID = 37;
