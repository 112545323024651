import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectProperty } from '../selectors';
import PerformanceTable from './PerformanceTable';

const BranchPerformance = ({ branchPerformanceData }) => (
  <PerformanceTable title="Branch" performanceData={branchPerformanceData} />
);

BranchPerformance.propTypes = {
  branchPerformanceData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  branchPerformanceData: makeSelectProperty('branchPerformanceData'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BranchPerformance);
