import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import reducer from './reducer';

import RouteChildViewer from '../../components/Layout/RouteChildViewer';
import { DEFAULT_PAGE_NO } from '../../config/constants/pagination';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import AnalyticsFilters from './AnalyticsFilters';
import Metrics from './Metrics';
import saga from './saga';
import * as selectors from './selectors';
import Summary from './Summary';
import OrderTable from './Tables/OrderTable';

import { makeSelectCurrentUser } from '../App/selectors';
import AvgBasketSizeChart from './AvgBasketSizeChart';
import BusyModeCard from './BusyModeCard';
import CompletedVsCancelledOrderChart from './CompletedVsCancelledOrderChart';
import GrossVsLostRevenueChart from './GrossVsLostRevenueChart';
import DiscountTable from './Tables/DiscountTable';
import ItemBreakDown from './Tables/ItemBreakDown';
import OosTableCard from './Tables/OOSTable/OosTableCard';
import PaymentMethodTable from './Tables/PaymentMethodTable';
import {
  NATIVE_ANALYTICS_SAGA,
  OOS_TABLE_TYPE,
  OOS_TABS,
} from '../../config/constants/analytics';
import PerformanceSummaryCard from './PerformanceSummary/PerformanceSummaryCard';

const NativeAnalytics = ({
  selectedAnalyticsProvider,
  analyticsStartDate,
  analyticsEndDate,
  selectedTimePeriod,
  getAnalyticsData,
  getOrdersData,
  paginationObj,
  handleUpdatePagination,
  getSummary,
  getStatus,
  isFirstTimeLoaded,
  selectedBusiness,
  selectedBranches,
  selectedBrands,
  resetAnalyticsData,
  getItemBreakdownData,
  isTimePeriodFilterStatusChanged,
  selectedChannel,
  getBusyModeAnalyticsData,
  getBusyModeLogData,
  getPaymentMethodData,
  handleGetList,
  analyticsStartTime,
  analyticsEndTime,
}) => {
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });

  // USE STATES
  const [isResetFilterApplied, setIsResetFilterApplied] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [oosDefaultTab, setOosDefaultTab] = useState(OOS_TABS[0]);
  const [oosDefaultTableType, setOosDefaultTableType] = useState(
    OOS_TABLE_TYPE.ITEMS.title,
  );
  const { pageNo, pageSize } = paginationObj || {};

  useEffect(() => {
    getStatus();
    handleAnalyticsFilterReset();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    if (isFirstTimeLoaded) {
      getAllData();
    }
  }, [isFirstTimeLoaded]);

  useEffect(() => {
    if (isResetFilterApplied) {
      getAllData();
    }
  }, [isResetFilterApplied]);

  // HANDLERS
  // =======
  const setToFirstPage = () => {
    if (pageNo !== DEFAULT_PAGE_NO)
      handleUpdatePagination(DEFAULT_PAGE_NO, pageSize);
  };

  const handleAnalyticParamData = () => {
    const hasBusinessId = selectedBusiness && selectedBusiness.id;
    const hasBranchIds = selectedBranches && selectedBranches.length;
    const hasBrandIds = selectedBrands && selectedBrands.length;
    const hasProviderIds =
      selectedAnalyticsProvider && selectedAnalyticsProvider.length;
    const hasChannelId = selectedChannel && selectedChannel.length;

    if (!hasBusinessId || (!hasBranchIds && !hasBrandIds)) {
      return null;
    }

    const startDate = format(analyticsStartDate, 'yyyy-MM-dd');
    const endDate = format(analyticsEndDate, 'yyyy-MM-dd');

    const selectedBrandIds = hasBrandIds
      ? selectedBrands.map(b => b.id).join(',')
      : '';

    const selectedBranchIds = hasBranchIds
      ? selectedBranches.map(b => b.id).join(',')
      : '';

    const providerIds = hasProviderIds
      ? selectedAnalyticsProvider.map(p => p.id).join(',')
      : [];

    const channelIds = hasChannelId
      ? selectedChannel.map(c => c.id).join(',')
      : [];

    const params = {
      provider_id: providerIds,
      branch_id: selectedBranchIds,
      brand_id: selectedBrandIds,
      period_start: startDate,
      period_hour_start: analyticsStartTime,
      period_hour_end: analyticsEndTime,
      period: selectedTimePeriod && selectedTimePeriod.id,
      order_source: channelIds,
    };

    if (startDate !== endDate) {
      params.period_end = endDate;
    }

    return { ...params };
  };

  const makeOrderDataRequest = params => {
    const orderParams = { ...params, page_no: pageNo, page_size: pageSize };
    getOrdersData(orderParams);
  };

  const getAllData = filterObj => {
    const { isMoreThan30Days } = filterObj || {};
    const params = handleAnalyticParamData();
    const hasParams = params && Object.keys(params).length > 0;

    if (hasParams) {
      getAnalyticsData(params);
      getSummary(params);
      if (!isMoreThan30Days) {
        makeOrderDataRequest(params);
      }
      const itemBreakdownParam = { ...params, activeTab };
      getItemBreakdownData(itemBreakdownParam);
      getBusyModeAnalyticsData(params);
      getBusyModeLogData(params);
      getPaymentMethodData(params);
      handleGetList({
        url: NATIVE_ANALYTICS_SAGA.OOS_REPORT_ITEM.GET,
        params,
        reducerKey: NATIVE_ANALYTICS_SAGA.OOS_REPORT_ITEM.KEY,
      });
    } else {
      resetAnalyticsData();
    }
  };
  const handleAnalyticsFilterApply = (e, filterObj) => {
    getAllData(filterObj);
    setIsResetFilterApplied(false);
    setToFirstPage();
    setOosDefaultTab(OOS_TABS[0]);
    setOosDefaultTableType(OOS_TABLE_TYPE.ITEMS.title);
  };
  const handleAnalyticsFilterReset = () => {
    setIsResetFilterApplied(true);
    setToFirstPage();
  };
  const handleItemVisibilityTab = id => {
    setActiveTab(id);
  };
  const handleDefaultTab = tab => {
    setOosDefaultTab(tab);
  };
  const handleDefaultTableType = tableType => {
    setOosDefaultTableType(tableType);
  };
  return (
    <RouteChildViewer headerTitle="Analytics">
      <Helmet>
        <title>Analytics</title>
        <meta name="title" content="Analytics" />
      </Helmet>
      <div className="w-full p-4 rounded bg-white mb-4">
        <AnalyticsFilters
          handleAnalyticsFilterApply={handleAnalyticsFilterApply}
          handleAnalyticsFilterReset={handleAnalyticsFilterReset}
        />
      </div>
      <div className="flex justify-between w-full mb-4">
        <Summary />
      </div>
      <PerformanceSummaryCard />
      <div className="flex justify-between w-full mb-4">
        <Metrics />
      </div>
      <div className="flex flex-col lg:flex-row justify-between w-full mb-4">
        <div className="bg-white rounded w-full lg:w-1/2 md:mr-4 mb-5 lg:mb-0">
          <GrossVsLostRevenueChart
            selectedTimePeriod={selectedTimePeriod}
            isTimePeriodFilterStatusChanged={isTimePeriodFilterStatusChanged}
          />
        </div>
        <div className="bg-white rounded w-full lg:w-1/2">
          <CompletedVsCancelledOrderChart
            selectedTimePeriod={selectedTimePeriod}
            isTimePeriodFilterStatusChanged={isTimePeriodFilterStatusChanged}
          />
        </div>
      </div>
      <div className="bg-white rounded w-full flex mt-5 mb-5">
        <OosTableCard
          filterData={handleAnalyticParamData()}
          oosDefaultTab={oosDefaultTab}
          handleDefaultTab={handleDefaultTab}
          oosDefaultTableType={oosDefaultTableType}
          handleDefaultTableType={handleDefaultTableType}
        />
      </div>
      <div className="bg-white rounded w-full flex mb-4">
        <AvgBasketSizeChart
          selectedTimePeriod={selectedTimePeriod}
          isTimePeriodFilterStatusChanged={isTimePeriodFilterStatusChanged}
        />
      </div>
      <div className="flex justify-between w-full mb-4">
        <PaymentMethodTable />
      </div>
      <div className="bg-white rounded w-full flex mt-5 mb-5">
        <ItemBreakDown
          handleItemVisibilityTab={handleItemVisibilityTab}
          filterData={handleAnalyticParamData()}
        />
      </div>
      <div className="flex justify-between w-full mb-4">
        <DiscountTable />
      </div>
      <div className=" w-full">
        <BusyModeCard
          selectedTimePeriod={selectedTimePeriod}
          isTimePeriodFilterStatusChanged={isTimePeriodFilterStatusChanged}
        />
      </div>
      <OrderTable
        selectedBusiness={selectedBusiness}
        filterData={handleAnalyticParamData()}
      />
    </RouteChildViewer>
  );
};

NativeAnalytics.propTypes = {
  selectedAnalyticsProvider: PropTypes.any,
  analyticsStartDate: PropTypes.any,
  analyticsEndDate: PropTypes.any,
  selectedTimePeriod: PropTypes.object,
  paginationObj: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  getAnalyticsData: PropTypes.func,
  getOrdersData: PropTypes.func,
  getSummary: PropTypes.func,
  getStatus: PropTypes.func,
  isFirstTimeLoaded: PropTypes.bool,
  selectedBusiness: PropTypes.object,
  selectedBranches: PropTypes.array,
  selectedBrands: PropTypes.array,
  resetAnalyticsData: PropTypes.func,
  getItemBreakdownData: PropTypes.func,
  isTimePeriodFilterStatusChanged: PropTypes.bool,
  selectedChannel: PropTypes.any,
  getBusyModeAnalyticsData: PropTypes.func,
  getBusyModeLogData: PropTypes.func,
  getPaymentMethodData: PropTypes.func,
  handleGetList: PropTypes.func,
  analyticsStartTime: PropTypes.any,
  analyticsEndTime: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  selectedAnalyticsProvider: selectors.makeSelectAnalyticsSelectedProvider(),
  analyticsStartDate: selectors.makeSelectAnalyticsStartDate(),
  analyticsEndDate: selectors.makeSelectAnalyticsEndDate(),
  selectedTimePeriod: selectors.makeSelectSelectedTimePeriod(),
  paginationObj: selectors.makeSelectPaginationObj(),
  isFirstTimeLoaded: selectors.makeSelectIsFirstTimeLoaded(),
  selectedBusiness: selectors.makeSelectAnalyticsSelectedBusiness(),
  selectedBranches: selectors.makeSelectAnalyticsSelectedBranch(),
  selectedBrands: selectors.makeSelectAnalyticsSelectedBrand(),
  currentUser: makeSelectCurrentUser(),
  isTimePeriodFilterStatusChanged:
    selectors.makeSelectSelectedTimePeriodStatus(),
  selectedChannel: selectors.makeSelectSelectedChannel(),
  analyticsStartTime: selectors.makeSelectProperty('analyticsStartTime'),
  analyticsEndTime: selectors.makeSelectProperty('analyticsEndTime'),
});

function mapDispatchToProps(dispatch) {
  return {
    getAnalyticsData: params => dispatch(actions.loadAnalyticsData(params)),
    getOrdersData: params => dispatch(actions.loadOrdersData(params)),
    handleUpdatePagination: (page, size) =>
      dispatch(actions.updatePagination(page, size)),
    getSummary: params => dispatch(actions.loadSummary(params)),
    getStatus: () => dispatch(actions.loadStatus()),
    resetAnalyticsData: () => dispatch(actions.resetAnalyticsData()),
    getItemBreakdownData: params =>
      dispatch(actions.loadItemBreakdownData(params)),
    getBusyModeAnalyticsData: params =>
      dispatch(actions.loadBusyModeAnalyticsData(params)),
    getBusyModeLogData: params => dispatch(actions.loadBusyModeLogData(params)),
    getPaymentMethodData: params =>
      dispatch(actions.loadPaymentMethodData(params)),
    handleGetList: params => dispatch(actions.getList(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(NativeAnalytics);
