/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import { produce } from 'immer';
import {
  COMPARISON_TYPE,
  DEFAULT_TIME_PERIOD,
} from '../../config/constants/analytics';
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
} from '../../config/constants/pagination';
import {
  getFlatPaymentMethodData,
  generateEntityWiseAnalyticsData,
  generatePerformanceAnalyticsData,
} from '../../utils/nativeAnalyticsUtils';
import { LOGOUT_DONE } from '../Auth/constants';
import * as constants from './constants';

// The initial state of the App
export const initialState = {
  summary: null,
  summaryLoading: false,
  analyticsData: [],
  analyticsDataLoading: false,
  ordersData: null,
  ordersDataLoading: false,
  businesses: [],
  selectedBusiness: null,
  businessLoading: false,
  brands: [],
  selectedBrand: [],
  brandLoading: false,
  branches: [],
  selectedBranch: [],
  branchLoading: false,
  analyticsProviders: [],
  selectedAnalyticsProvider: [],
  analyticsProvidersLoading: false,
  analyticsStartDate: new Date(),
  analyticsEndDate: new Date(),
  analyticsStartTime: '',
  analyticsEndTime: '',
  analyticsHourReset: false,
  selectedTimePeriod: DEFAULT_TIME_PERIOD,
  comparisonEnabled: true,
  pagination: {
    pageNo: DEFAULT_PAGE_NO,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  analyticsOrderStatus: {},
  isFirstTimeLoaded: false,
  timePeriodSlug: 'daily',
  selectedProviderListForProviderComparison: [],
  itemBreakDownData: null,
  itemBreakDownDataLoading: false,
  isTimePeriodFilterStatusChanged: false,
  selectedChannel: null,
  busyModeAnalyticsData: null,
  busyModeAnalyticsDataLoading: false,
  busyModeLogData: null,
  busyModeLogDataLoading: false,
  paymentMethodData: null,
  paymentMethodDataLoading: false,
  isMoreThan30Days: false,
  selectedComparisonType: COMPARISON_TYPE.AGGREGATOR,
  listLoading: false,
  itemReportData: null,
  modifierReportData: null,
  itemLogData: null,
  modifierLogData: null,
  paymentMethodAnalytics: [],
  comparisonBrandsChartOne: [],
  comparisonBrandsChartTwo: [],
  branchWiseAnalyticsData: {},
  brandWiseAnalyticsData: {},
  brandPerformanceData: {},
  branchPerformanceData: {},
};

/* eslint-disable default-case, no-param-reassign */
const nativeAnalyticsReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { selectedBusiness } = draft;
    const actionParamKeys = Object.keys(action.params || {});

    switch (action.type) {
      case constants.LOAD_SUMMARY:
        draft.summaryLoading = true;
        break;
      case constants.LOAD_SUMMARY_SUCCESSFUL:
        draft.summary = action.summary;
        draft.summaryLoading = initialState.summaryLoading;
        break;
      case constants.LOAD_SUMMARY_FAILED:
        draft.summaryLoading = initialState.summaryLoading;
        break;
      case constants.LOAD_ANALYTICS_DATA:
        draft.analyticsDataLoading = true;
        break;
      case constants.LOAD_ANALYTICS_DATA_SUCCESSFUL: {
        const { analyticsData } = action;
        draft.analyticsDataLoading = initialState.analyticsDataLoading;
        draft.analyticsData = analyticsData;
        const brandWiseAnalyticsData = generateEntityWiseAnalyticsData(
          analyticsData,
          'brand_id',
        );
        draft.brandWiseAnalyticsData = brandWiseAnalyticsData;
        draft.brandPerformanceData = generatePerformanceAnalyticsData(
          analyticsData,
          brandWiseAnalyticsData,
          draft.selectedBrand,
        );
        const branchWiseAnalyticsData = generateEntityWiseAnalyticsData(
          analyticsData,
          'branch_id',
        );
        draft.branchWiseAnalyticsData = branchWiseAnalyticsData;
        draft.branchPerformanceData = generatePerformanceAnalyticsData(
          analyticsData,
          branchWiseAnalyticsData,
          draft.selectedBranch,
        );
        break;
      }
      case constants.LOAD_ANALYTICS_DATA_FAILED:
        draft.analyticsDataLoading = initialState.analyticsDataLoading;
        break;
      case constants.LOAD_ORDERS_DATA:
        draft.ordersDataLoading = true;
        break;
      case constants.LOAD_ORDERS_DATA_SUCCESSFUL:
        draft.ordersDataLoading = initialState.ordersDataLoading;
        if (selectedBusiness) {
          draft.ordersData = action.ordersData;
        }
        break;
      case constants.LOAD_ORDERS_DATA_FAILED:
        draft.ordersDataLoading = initialState.ordersDataLoading;
        break;
      case constants.LOAD_ANALYTICS_BUSINESS:
        draft.businessLoading = true;
        break;
      case constants.LOAD_ANALYTICS_BUSINESS_SUCCESSFUL:
        draft.businesses = [...action.businesses];
        draft.businessLoading = false;
        break;
      case constants.LOAD_ANALYTICS_BUSINESS_FAILED:
        draft.businessLoading = false;
        break;
      case constants.CHANGE_ANALYTICS_BUSINESS:
        draft.selectedBusiness =
          action.selectedBusiness && action.selectedBusiness.id
            ? { ...action.selectedBusiness }
            : null;
        break;
      case constants.LOAD_ANALYTICS_BRANDS:
        draft.brandLoading = true;
        break;
      case constants.LOAD_ANALYTICS_BRANDS_SUCCESSFUL:
        draft.brands = action.brands;
        if (draft.selectedBusiness) {
          draft.selectedBrand = [...action.brands];
        }
        draft.brandLoading = false;
        break;
      case constants.LOAD_ANALYTICS_BRANDS_FAILED:
        draft.brandLoading = false;
        break;
      case constants.CHANGE_ANALYTICS_BRAND:
        draft.selectedBrand = [...action.selectedBrand];
        break;
      case constants.LOAD_ANALYTICS_BRANCHES:
        draft.branchLoading = true;
        break;
      case constants.LOAD_ANALYTICS_BRANCHES_SUCCESSFUL:
        draft.branches = action.branches;
        if (draft.selectedBusiness) {
          draft.selectedBranch = [...action.branches];
        }
        draft.branchLoading = false;
        break;
      case constants.LOAD_ANALYTICS_BRANCHES_FAILED:
        draft.branchLoading = false;
        break;
      case constants.CHANGE_ANALYTICS_BRANCH:
        draft.selectedBranch = action.selectedBranch;
        break;
      case constants.LOAD_ANALYTICS_PROVIDERS:
        draft.analyticsProvidersLoading = true;
        break;
      case constants.LOAD_ANALYTICS_PROVIDERS_SUCCESS:
        draft.analyticsProviders = [...action.analyticsProviders];
        draft.selectedProviderListForProviderComparison = [
          ...action.analyticsProviders,
        ];
        draft.analyticsProvidersLoading = false;
        break;
      case constants.LOAD_ANALYTICS_PROVIDERS_FAILED:
        draft.analyticsProvidersLoading = false;
        break;
      case constants.CHANGE_ANALYTICS_PROVIDER:
        draft.selectedAnalyticsProvider = action.selectedAnalyticsProvider;
        break;
      case constants.CHANGE_ANALYTICS_START_DATE:
        draft.analyticsStartDate = action.analyticsStartDate;
        break;
      case constants.CHANGE_ANALYTICS_END_DATE:
        draft.analyticsEndDate = action.analyticsEndDate;
        break;
      case constants.CHANGE_ANALYTICS_TIME_PERIOD:
        draft.selectedTimePeriod = action.selectedTimePeriod;
        break;
      case constants.CHANGE_TIME_PERIOD_FILTER_STATUS:
        draft.isTimePeriodFilterStatusChanged =
          action.isTimePeriodFilterStatusChanged;
        break;
      case constants.CHANGE_COMPARISON_TOGGLE:
        draft.comparisonEnabled = action.comparisonEnabled;
        break;
      case constants.LOAD_STATUS_SUCCESS:
        draft.analyticsOrderStatus = action.analyticsOrderStatus;
        break;
      case constants.CHANGE_IS_LOADED_FIRST_TIME:
        draft.isFirstTimeLoaded = action.isFirstTimeLoaded;
        break;
      case constants.CHANGE_TIME_PERIOD_SLUG_FOR_DATA_CALCULATION:
        draft.timePeriodSlug = action.timePeriodSlug;
        break;
      case constants.CHANGE_SELECTED_PROVIDERS_LIST_FOR_PROVIDER_COMPARISON:
        draft.selectedProviderListForProviderComparison =
          action.selectedProviderListForProviderComparison;
        break;
      case constants.RESET_ANALYTICS_DATA:
        draft.analyticsData = initialState.analyticsData;
        draft.summary = initialState.summary;
        draft.ordersData = initialState.ordersData;
        draft.selectedBrand = initialState.selectedBrand;
        draft.selectedBranch = initialState.selectedBranch;
        draft.selectedAnalyticsProvider =
          initialState.selectedAnalyticsProvider;
        draft.paymentMethodAnalytics = initialState.paymentMethodAnalytics;
        draft.busyModeAnalyticsData = initialState.busyModeAnalyticsData;
        draft.busyModeLogData = initialState.busyModeLogData;
        draft.itemBreakDownData = initialState.itemBreakDownData;
        draft.itemReportData = initialState.itemReportData;
        draft.modifierReportData = initialState.modifierReportData;
        draft.itemLogData = initialState.itemLogData;
        draft.modifierLogData = initialState.modifierLogData;
        draft.analyticsStartTime = initialState.analyticsStartTime;
        draft.analyticsEndTime = initialState.analyticsEndTime;
        draft.comparisonBrandsChartOne = initialState.comparisonBrandsChartOne;
        draft.comparisonBrandsChartTwo = initialState.comparisonBrandsChartTwo;
        draft.branchWiseAnalyticsData = initialState.branchWiseAnalyticsData;
        draft.brandWiseAnalyticsData = initialState.brandWiseAnalyticsData;
        draft.brandPerformanceData = initialState.brandPerformanceData;
        draft.branchPerformanceData = initialState.branchPerformanceData;
        break;
      case constants.LOAD_ITEM_BREAKDOWN_DATA:
        draft.itemBreakDownDataLoading = true;
        break;
      case constants.LOAD_ITEM_BREAKDOWN_DATA_SUCCESSFUL:
        draft.itemBreakDownDataLoading = initialState.ordersDataLoading;
        draft.itemBreakDownData = action.itemBreakDownData;
        break;
      case constants.LOAD_ITEM_BREAKDOWN_DATA_FAILED:
        draft.itemBreakDownDataLoading = initialState.ordersDataLoading;
        break;
      case constants.CHANGE_OWN_CHANNEL:
        draft.selectedChannel = [...action.selectedChannel];
        break;
      case constants.LOAD_BUSY_MODE_ANALYTICS_DATA:
        draft.busyModeAnalyticsDataLoading = true;
        break;
      case constants.LOAD_BUSY_MODE_ANALYTICS_DATA_SUCCESSFUL:
        draft.busyModeAnalyticsDataLoading =
          initialState.busyModeAnalyticsDataLoading;
        draft.busyModeAnalyticsData = action.busyModeAnalyticsData;
        break;
      case constants.LOAD_BUSY_MODE_ANALYTICS_DATA_FAILED:
        draft.busyModeAnalyticsDataLoading = initialState.analyticsDataLoading;
        break;
      case constants.LOAD_BUSY_MODE_LOG_DATA:
        draft.busyModeLogDataLoading = true;
        break;
      case constants.LOAD_BUSY_MODE_LOG_DATA_SUCCESSFUL:
        draft.busyModeLogDataLoading = initialState.busyModeLogDataLoading;
        draft.busyModeLogData = action.busyModeLogData;
        break;
      case constants.LOAD_BUSY_MODE_LOG_DATA_FAILED:
        draft.busyModeLogDataLoading = initialState.busyModeLogDataLoading;
        break;
      case constants.LOAD_PAYMENT_METHOD_DATA:
        draft.paymentMethodDataLoading = true;
        break;
      case constants.LOAD_PAYMENT_METHOD_DATA_SUCCESSFUL:
        draft.paymentMethodDataLoading = false;
        draft.paymentMethodAnalytics = getFlatPaymentMethodData(
          action.paymentMethodAnalytics,
        );
        break;
      case constants.LOAD_PAYMENT_METHOD_DATA_FAILED:
        draft.paymentMethodDataLoading = false;
        break;
      case constants.CHANGE_MORE_THAN_30_DAYS_STATUS:
        draft.isMoreThan30Days = action.isMoreThan30Days;
        break;
      case constants.CHANGE_COMPARISON_TYPE:
        draft.selectedComparisonType = action.selectedComparisonType;
        break;
      case constants.GET_LIST:
        draft.listLoading = true;
        break;
      case constants.GET_LIST_SUCCESS:
        draft.listLoading = false;
        draft[action.payload.key] = action.payload.value;
        break;
      case constants.GET_LIST_FAILED:
        draft.listLoading = false;
        draft[action.payload.key] = initialState[action.payload.key];
        break;
      case constants.UPDATE_PAGINATION:
        draft.pagination.pageNo = action.pageNo;
        draft.pagination.pageSize = action.pageSize;
        break;
      case constants.CHANGE_ENTITY:
        draft[action.payload.key] = action.payload.value;
        break;
      case constants.EDIT_FIELD:
        actionParamKeys.forEach(key => {
          draft[key] = action.params[key];
        });
        break;
      case LOGOUT_DONE:
        return initialState;
    }
    return draft;
  });

export default nativeAnalyticsReducer;
